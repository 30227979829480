import { useEffect, useState, useContext } from 'react'
import { Contexto } from '../../../../index'
import * as e from './PreventistasCSS'
import * as api from '../../../../../../Api/Api'
import * as fg from '../../../../../../Api/FuncionesGlobales'
import agregar from './../../../../../../Imagenes/Iconos/agregar.png'
import editar from './../../../../../../Imagenes/Iconos/editar.png'
import borrar from './../../../../../../Imagenes/Iconos/borrar.png'
import Boton from '../../../../../../Elementos/Boton'
import MAgregarEmpresa from './MAgregarPreventista'
import MEliminarEmpresa from './MEliminarPreventista'

function Preventistas () {
  const { esComputadora } = useContext(Contexto)

  const [listado, setListado] = useState([])
  //eventos
  const [elemSelec, setElemSelec] = useState(null)
  const [dscElemSelec, setDscElemSelec] = useState(null)
  //modales
  const [verModal, setVerModal] = useState(false)
  const [tipoAccion, setTipoAccion] = useState('')

  useEffect(() => {
    if (!verModal) {
      const inicio = async () => {
        const listaPreventistasCall = await api.listarPreventistas(null, null)
        console.log(listaPreventistasCall)
        if (listaPreventistasCall) {
          if (listaPreventistasCall.SMListaErrores.length === 0) {
            setListado(listaPreventistasCall.preventistas)
          }
        }
      }
      inicio()
    }
  }, [verModal])

  const cerrarModal = e => {
    setVerModal(false)
  }
  return (
    <>
      {verModal && (
        <div>
          {tipoAccion === 'agregarEmpresa' || tipoAccion === 'editarEmpresa' ? (
            <MAgregarEmpresa
              tipoAccion={tipoAccion}
              cerrarModal={cerrarModal}
              esComputadora={esComputadora}
              codigoElem={elemSelec}
            />
          ) : (
            <MEliminarEmpresa
              dscElemSelec={dscElemSelec}
              tipoAccion={tipoAccion}
              cerrarModal={cerrarModal}
              esComputadora={esComputadora}
              codigoElem={elemSelec}
            />
          )}
        </div>
      )}
      <div id='contenedor' style={e.contenedor(esComputadora)}>
        <div id='titulo' style={e.titulo(esComputadora)}>
          Preventistas
        </div>
        <div id='centro' style={e.centro(esComputadora)}>
          <div id='listado' style={e.listado(esComputadora)}>
            <div id='cabeceraListado' style={e.cabeceraListado(esComputadora)}>
              <div
                style={{
                  borderLeft: '0.1vw solid black',
                  ...e.elemCabListado(esComputadora)
                }}
              >
                Nombre
              </div>
              <div style={e.elemCabListado(esComputadora)}>Celular</div>
              <div style={e.elemCabListado(esComputadora)}>Empresa</div>
              <div style={e.elemCabListado(esComputadora)}>DNI</div>
              <div style={e.elemCabListado(esComputadora)}>Correo</div>
              <div style={e.elemCabListado(esComputadora)}>Dirección</div>
              <div style={e.elemCabListado(esComputadora)}>Activo</div>
              <div style={e.elemCabListado(esComputadora)}>Opciones</div>
            </div>
            {listado.length > 0 &&
              listado.map(emp => (
                <div
                  key={emp.usuario}
                  onMouseEnter={() => {
                    setElemSelec(emp.usuario)
                  }}
                  onMouseLeave={() => {
                    setElemSelec(null)
                  }}
                  style={e.cuerpoListado(esComputadora)}
                >
                  <div
                    style={{
                      ...e.elemCuerpoListado(esComputadora),
                      ...(emp.activo === 'N' && e.inactivoElem(esComputadora)),
                      ...(elemSelec === emp.usuario &&
                        e.elemCuerpoHoverListado(esComputadora))
                      //justifyContent: "left"
                    }}
                  >
                    {emp.nombre +
                      ' ' +
                      emp.apellido_materno +
                      ' ' +
                      emp.apellido_paterno}
                  </div>

                  <div
                    style={{
                      ...e.elemCuerpoListado(esComputadora),
                      ...(emp.activo === 'N' && e.inactivoElem(esComputadora)),
                      ...(elemSelec === emp.usuario &&
                        e.elemCuerpoHoverListado(esComputadora))
                    }}
                  >
                    {emp.celular}
                  </div>
                  <div
                    style={{
                      ...e.elemCuerpoListado(esComputadora),
                      ...(emp.activo === 'N' && e.inactivoElem(esComputadora)),
                      ...(elemSelec === emp.usuario &&
                        e.elemCuerpoHoverListado(esComputadora))
                    }}
                  >
                    {emp.empresa.nombre}
                  </div>
                  <div
                    style={{
                      ...e.elemCuerpoListado(esComputadora),
                      ...(emp.activo === 'N' && e.inactivoElem(esComputadora)),
                      ...(elemSelec === emp.usuario &&
                        e.elemCuerpoHoverListado(esComputadora))
                    }}
                  >
                    {emp.dni}
                  </div>
                  <div
                    style={{
                      ...e.elemCuerpoListado(esComputadora),
                      ...(emp.activo === 'N' && e.inactivoElem(esComputadora)),
                      ...(elemSelec === emp.usuario &&
                        e.elemCuerpoHoverListado(esComputadora))
                    }}
                  >
                    {emp.correo === '' ? '-' : emp.correo}
                  </div>
                  <div
                    style={{
                      ...e.elemCuerpoListado(esComputadora),
                      ...(emp.activo === 'N' && e.inactivoElem(esComputadora)),
                      ...(elemSelec === emp.usuario &&
                        e.elemCuerpoHoverListado(esComputadora))
                    }}
                  >
                    {emp.direccion === '' ? '-' : emp.direccion}
                  </div>
                  <div
                    style={{
                      ...e.elemCuerpoListado(esComputadora),
                      ...(emp.activo === 'N' && e.inactivoElem(esComputadora)),
                      ...(elemSelec === emp.usuario &&
                        e.elemCuerpoHoverListado(esComputadora))
                    }}
                  >
                    {emp.activo === 'S' ? 'Si' : 'No'}
                  </div>
                  <div
                    style={{
                      ...e.elemCuerpoOpListado(esComputadora),
                      ...(emp.activo === 'N' && e.inactivoElem(esComputadora)),
                      ...(elemSelec === emp.usuario &&
                        e.elemCuerpoHoverListado(esComputadora))
                    }}
                  >
                    {
                      false && <>
                        <img
                          src={editar}
                          style={e.img(esComputadora)}
                          onClick={e => {
                            setVerModal(true)
                            setTipoAccion('editarEmpresa')
                          }}
                        />
                        <img
                          src={borrar}
                          style={e.img(esComputadora)}
                          onClick={e => {
                            setDscElemSelec(
                              e.currentTarget.parentNode.parentNode
                                .firstElementChild.innerHTML
                            )
                            setVerModal(true)
                            setTipoAccion('eliminarEmpresa')
                          }}
                        />
                      </>
                    }
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div style={e.opciones(esComputadora)}>
          <div>
            <Boton
              style={{
                backgroundColor: '#2D8B0B',
                borderRadius: esComputadora ? '0.5vw' : '1vw',
                padding: esComputadora ? '0.8vw' : '2vw'
              }}
              textoStyle={{
                color: 'white',
                width: '100%',
                fontSize: esComputadora ? '1vw' : '2.5vw'
              }}
              texto='Agregar Preventista'
              tipo='submit'
              estaProcesando={false}
              id='agregarEmpresa'
              onClick={e => {
                setVerModal(true)
                setTipoAccion(e.currentTarget.id)
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Preventistas
