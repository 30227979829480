import React, { useState, useEffect, useRef, useCallback } from 'react'
import * as estilos from './MDetalleListaIngresoCSS'
import Boton from '../../../../../Elementos/Boton'
import Input from '../../../../../Elementos/Input'
import Opciones from '../../../../../Elementos/Opciones'
import * as api from '../../../../../Api/Api'
import * as fg from '../../../../../Api/FuncionesGlobales'

function MDetalleListaIngreso (param) {
  const [estaProcesando, setestaProcesando] = useState(false)

  const [listPedIns, setListPedIns] = useState([])
  const [pedidoinfo, setpedidoinfo] = useState(null)

  const [fecsolin, setfecsolin] = useState('')
  const [fecaprobin, setfecaprobin] = useState('')
  const [fecingin, setfecingin] = useState('')
  const [comenpedin, setcomenpedin] = useState('')
  const [estpedin, setestpedin] = useState('')
  const [cantenin, setcantenin] = useState('')

  useEffect(() => {
    console.log(param)
    const inicio = async () => {
      const detalleListaPedidoInsu = await api.pedidoInsumoDetalle(
        parseInt(param.codelemselec),
        0,
        0
      )
      console.log(detalleListaPedidoInsu)
      console.log(
        fg.formatear_fecha_ddmmyyyy(
          fg.formatear_fecha_ddmmyyyy_hora12(
            detalleListaPedidoInsu.pedido.fecha_solicitud,
            'A'
          ),
          'yyyy-mm-dd'
        )
      )
      setfecsolin(
        detalleListaPedidoInsu.pedido.fecha_solicitud === null
          ? ''
          : fg.formatear_fecha_ddmmyyyy(
              fg.formatear_fecha_ddmmyyyy_hora12(
                detalleListaPedidoInsu.pedido.fecha_solicitud,
                'A'
              ),
              'yyyy-mm-dd'
            )
      )
      setfecaprobin(
        detalleListaPedidoInsu.pedido.fecha_pedido === null
          ? ''
          : fg.formatear_fecha_ddmmyyyy(
              fg.formatear_fecha_ddmmyyyy_hora12(
                detalleListaPedidoInsu.pedido.fecha_pedido,
                'A'
              ),
              'yyyy-mm-dd'
            )
      )

      setfecingin(
        detalleListaPedidoInsu.pedido.fecha_ingreso === null
          ? ''
          : fg.formatear_fecha_ddmmyyyy(
              fg.formatear_fecha_ddmmyyyy_hora12(
                detalleListaPedidoInsu.pedido.fecha_ingreso,
                'A'
              ),
              'yyyy-mm-dd'
            )
      )
      setcomenpedin(detalleListaPedidoInsu.pedido.descripcion)
      setestpedin(detalleListaPedidoInsu.pedido.estado)
      setListPedIns(detalleListaPedidoInsu.insumosPedidoJson)
      setpedidoinfo(detalleListaPedidoInsu.pedido)
    }
    inicio()
  }, [])

  const salgodeinput = async e => {
    let codmarca = parseInt(
      e.currentTarget.parentNode.parentNode.parentNode.id
    )
    console.log(e.currentTarget.parentNode.parentNode.parentNode)
    console.log(e.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode
      )
    let codinsumo = parseInt(
      e.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode.id
    )

    let valor =
      e.currentTarget.value === '' ? 0 : parseFloat(e.currentTarget.value)
    const editarInput = await api.editarPedidoInsumoDetalle(
      pedidoinfo.pedido,
      valor,
      2,
      codinsumo,
      codmarca
    )
    if (editarInput) {
      if (editarInput.SMListaErrores.length === 0) {
      } else {
        console.log(editarInput)
      }
    }
  }
  return (
    <>
      <div id='baseModal' style={estilos.baseModal(param.esComputadora)}>
        <div
          id='contenedorModal'
          style={estilos.contenedorModal(param.esComputadora)}
        >
          <div style={estilos.botonCerrarModalStyle(param.esComputadora)}>
            <Boton
              style={{
                backgroundColor: '#E12121',
                borderRadius: param.esComputadora ? '0.5vw' : '1vw',
                padding: param.esComputadora ? '0.8vw' : '2vw'
              }}
              textoStyle={{
                color: 'white',
                width: '100%',
                fontSize: param.esComputadora ? '1vw' : '2.5vw'
              }}
              texto='X'
              tipo='submit'
              tonalidad='20'
              estaProcesando={estaProcesando}
              onClick={() => {
                param.cerrarModal()
              }}
            />
          </div>
          <div style={estilos.tituloCSS(param.esComputadora)}>
            {'Detalle Lista Pedido' + ' (' + param.dscelemeselec + ')'}
          </div>
          <div
            id='contenidoModal'
            style={estilos.contenidoModal(param.esComputadora)}
          >
            <div style={estilos.formularioCSS(param.esComputadora)}>
              <div style={estilos.codigocab(param.esComputadora)}>
                {'Nro. ' + String(param.codelemselec).padStart(8, '0')}
              </div>
              <div style={estilos.cabecera(param.esComputadora)}>
                <div style={estilos.fechaSol(param.esComputadora)}>
                  <Input
                    style={{
                      fontWeight: 'bolder',
                      fontSize: param.esComputadora ? '1.2vw' : '2.5vw'
                    }}
                    textoStyle={{
                      fontWeight: 'bolder',
                      fontSize: param.esComputadora ? '1.2vw' : '2.5vw'
                    }}
                    id='fechaPedido'
                    moderno='Fecha de Solicitud'
                    tipo='date'
                    autoComplete
                    onChange={() => {}}
                    valorInicial={fecsolin}
                    deshabilitado
                    esComputadora={param.esComputadora}
                  />
                </div>
                <div style={estilos.fechaAprob(param.esComputadora)}>
                  <Input
                    style={{
                      fontWeight: 'bolder',
                      fontSize: param.esComputadora ? '1.2vw' : '2.5vw'
                    }}
                    textoStyle={{
                      fontWeight: 'bolder',
                      fontSize: param.esComputadora ? '1.2vw' : '2.5vw'
                    }}
                    id='fechaPedido'
                    moderno='Fecha Aprobación'
                    tipo='date'
                    autoComplete
                    onChange={() => {}}
                    valorInicial={fecaprobin}
                    deshabilitado
                    esComputadora={param.esComputadora}
                  />
                </div>
                <div style={estilos.fechaIng(param.esComputadora)}>
                  <Input
                    style={{
                      fontWeight: 'bolder',
                      fontSize: param.esComputadora ? '1.2vw' : '2.5vw'
                    }}
                    textoStyle={{
                      fontWeight: 'bolder',
                      fontSize: param.esComputadora ? '1.2vw' : '2.5vw'
                    }}
                    id='fechaPedido'
                    moderno='Fecha de Ingreso'
                    tipo='date'
                    autoComplete
                    onChange={() => {}}
                    valorInicial={fecingin}
                    deshabilitado
                    esComputadora={param.esComputadora}
                  />
                </div>
              </div>
              <div style={estilos.cuerpo(param.esComputadora)}>
                <div>
                  <Input
                    style={{
                      //fontWeight: 'bolder',
                      fontSize: param.esComputadora ? '1.2vw' : '2.5vw'
                    }}
                    textoStyle={{
                      fontWeight: 'bolder',
                      fontSize: param.esComputadora ? '1.2vw' : '2.5vw'
                    }}
                    id='fechaPedido'
                    moderno='Comentario de Pedido'
                    tipo='text'
                    onChange={() => {}}
                    valorInicial={comenpedin}
                    maxDigitos='50'
                    esComputadora={param.esComputadora}
                    deshabilitado={
                      estpedin === 3 ||
                      (param.rol === 'SUPER ADMINISTRADOR' && estpedin === 2)
                        ? true
                        : false
                    }
                  />
                </div>
                <div
                  id='contitem'
                  style={estilos.contenidoItems(param.esComputadora)}
                >
                  {listPedIns.length > 0 &&
                    listPedIns.map(lp => (
                      <div
                        id={lp.insumo.codigo}
                        key={lp.insumo.codigo}
                        style={estilos.cuerpoitem(param.esComputadora)}
                      >
                        <div style={estilos.cuerpoitemcab(param.esComputadora)}>
                          {lp.insumo.nombre +
                            ' (' +
                            lp.insumo.unidades +
                            ' ' +
                            lp.insumo.unidad_medida +
                            ')'}
                        </div>
                        <div style={estilos.cuerpoitemcue(param.esComputadora)}>
                          <div
                            style={{
                              ...estilos.cuerpoitemcuecab(param.esComputadora),
                              fontWeight: 'bolder',
                              //height: '20%'
                            }}
                          >
                            <div style={estilos.campo0(param.esComputadora)}>
                              Marca Nombre
                            </div>
                            <div style={estilos.campo1(param.esComputadora)}>
                              Cant. Solicitada
                            </div>
                            <div style={estilos.campo2(param.esComputadora)}>
                              Cant. Ingreso
                            </div>
                          </div>
                          {lp.detallePedido.length > 0 &&
                            lp.detallePedido.map(dp => (
                              <div
                                id={dp.marca.codigo}
                                key={dp.marca.codigo}
                                style={{
                                  ...estilos.cuerpoitemcuecue(
                                    param.esComputadora
                                  )
                                  //height: '80%'
                                }}
                              >
                                <div
                                  style={estilos.campo0(param.esComputadora)}
                                >
                                  {dp.marca.nombre}
                                </div>
                                <div
                                  style={estilos.campo1(param.esComputadora)}
                                >
                                  {dp.solicitado}
                                </div>
                                <div
                                  style={estilos.campo2(param.esComputadora)}
                                >
                                  {param.rol === 'SUPER ADMINISTRADOR' ||
                                  (param.rol === 'ALMACENERO' &&
                                    pedidoinfo.estado !== 2) ? (
                                    dp.entregado
                                  ) : (
                                    <Input
                                      style={{
                                        fontWeight: 'bolder',
                                        fontSize: param.esComputadora
                                          ? '1.2vw'
                                          : '2.5vw',
                                        padding: param.esComputadora
                                          ? '0.2vw'
                                          : '0.7vw',
                                        width: '100%'
                                      }}
                                      textoStyle={{
                                        fontWeight: 'bolder',
                                        fontSize: param.esComputadora
                                          ? '1vw'
                                          : '2.5vw'
                                      }}
                                      id='inputin'
                                      moderno=''
                                      tipo='number'
                                      maxDigitos='10'
                                      autoComplete
                                      onChange={e => {
                                        if (e.currentTarget.value === '') {
                                          salgodeinput(e)
                                        }
                                      }}
                                      onBlur={salgodeinput}
                                      valorInicial={dp.entregado}
                                      esComputadora={param.esComputadora}
                                    />
                                  )}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div style={estilos.opcionesBotones(param.esComputadora)}>
                <div>
                  {pedidoinfo !== null &&
                    ((param.rol === 'SUPER ADMINISTRADOR' &&
                      pedidoinfo.estado === 1) ||
                      (param.rol === 'ALMACENERO' &&
                        pedidoinfo.estado === 2)) && (
                      <Boton
                        style={{
                          backgroundColor: '#2D8B0B',
                          borderRadius: param.esComputadora ? '0.5vw' : '1vw',
                          padding: param.esComputadora ? '0.8vw' : '2vw'
                        }}
                        textoStyle={{
                          color: 'white',
                          width: '100%',
                          fontSize: param.esComputadora ? '1vw' : '2.5vw'
                        }}
                        texto={
                          param.rol === 'SUPER ADMINISTRADOR'
                            ? 'Aprobar Pedido'
                            : 'Finalizar Ingreso'
                        }
                        tipo='submit'
                        tonalidad='20'
                        estaProcesando={false}
                        onClick={async () => {
                          const aprobar = await api.guardarPedidoInsumo(
                            parseInt(pedidoinfo.pedido),
                            comenpedin,
                            param.rol === 'SUPER ADMINISTRADOR' ? 2 : 3
                          )
                          if (aprobar) {
                            if (aprobar.SMListaErrores.length === 0) {
                              param.cerrarModal()
                            } else {
                              console.log(aprobar)
                            }
                          }
                        }}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MDetalleListaIngreso
