import React, { useState, useEffect, useRef, useCallback } from 'react'
import * as estilos from './MDetalleListaSalidaCSS'
import Boton from '../../../../../Elementos/Boton'
import Input from '../../../../../Elementos/Input'
import Opciones from '../../../../../Elementos/Opciones'
import * as api from '../../../../../Api/Api'
import * as fg from '../../../../../Api/FuncionesGlobales'

function MDetalleListaSalida (param) {
  const [estaProcesando, setestaProcesando] = useState(false)

  const [listSalIns, setlistSalIns] = useState([])

  const [fecsolin, setfecsolin] = useState('')

  useEffect(() => {
    console.log(param)

    const inicio = async () => {
      const salinsudetCall = await api.salidaInsumoDetalle(
        param.codelemselec,
        param.codelemselec2,
        param.codelemselec3
      )
    
      setfecsolin(
        salinsudetCall.salida.fecha === null
          ? ''
          : fg.formatear_fecha_ddmmyyyy(
              fg.formatear_fecha_ddmmyyyy_hora12(
                salinsudetCall.salida.fecha,
                'A'
              ),
              'yyyy-mm-dd'
            )
      )

      setlistSalIns(salinsudetCall.insumosSalidaJson)
    }
    inicio()
  }, [])

  
  return (
    <>
      <div id='baseModal' style={estilos.baseModal(param.esComputadora)}>
        <div
          id='contenedorModal'
          style={estilos.contenedorModal(param.esComputadora)}
        >
          <div style={estilos.botonCerrarModalStyle(param.esComputadora)}>
            <Boton
              style={{
                backgroundColor: '#E12121',
                borderRadius: param.esComputadora ? '0.5vw' : '1vw',
                padding: param.esComputadora ? '0.8vw' : '2vw'
              }}
              textoStyle={{
                color: 'white',
                width: '100%',
                fontSize: param.esComputadora ? '1vw' : '2.5vw'
              }}
              texto='X'
              tipo='submit'
              tonalidad='20'
              estaProcesando={estaProcesando}
              onClick={() => {
                param.cerrarModal()
              }}
            />
          </div>
          <div style={estilos.tituloCSS(param.esComputadora)}>
            {'Detalle Lista Salida' + ' (' + param.motivo +'-'+fg.capitalizar_palabra(param.maestro)+ ')'}
          </div>
          <div
            id='contenidoModal'
            style={estilos.contenidoModal(param.esComputadora)}
          >
            <div style={estilos.formularioCSS(param.esComputadora)}>
              <div style={estilos.codigocab(param.esComputadora)}>
                {'Nro. ' + String(param.codelemselec).padStart(8, '0')}
              </div>
              <div style={estilos.cabecera(param.esComputadora)}>
                <div style={estilos.fechaSol(param.esComputadora)}>
                  <Input
                    style={{
                      fontWeight: 'bolder',
                      fontSize: param.esComputadora ? '1.2vw' : '2.5vw'
                    }}
                    textoStyle={{
                      fontWeight: 'bolder',
                      fontSize: param.esComputadora ? '1.2vw' : '2.5vw'
                    }}
                    id='fechaPedido'
                    moderno='Fecha de Salida'
                    tipo='date'
                    autoComplete
                    onChange={() => {}}
                    valorInicial={fecsolin}
                    deshabilitado
                    esComputadora={param.esComputadora}
                  />
                </div>
              </div>
              <div style={estilos.cuerpo(param.esComputadora)}>
                <div
                  id='contitem'
                  style={estilos.contenidoItems(param.esComputadora)}
                >
                  {listSalIns.length > 0 &&
                    listSalIns.map(lsip => (
                      <div
                        id={lsip.insumo.codigo}
                        key={lsip.insumo.codigo}
                        style={estilos.cuerpoitem(param.esComputadora)}
                      >
                        <div style={estilos.cuerpoitemcab(param.esComputadora)}>
                          {lsip.insumo.nombre +
                            ' (' +
                            lsip.insumo.unidades +
                            ' ' +
                            lsip.insumo.unidad_medida +
                            ')'}
                        </div>
                        <div style={estilos.cuerpoitemcue(param.esComputadora)}>
                          <div
                            style={{
                              ...estilos.cuerpoitemcuecab(param.esComputadora),
                              fontWeight: 'bolder'
                              //height: '20%'
                            }}
                          >
                            <div style={estilos.campo0(param.esComputadora)}>
                              Marca Nombre
                            </div>

                            <div style={estilos.campo2(param.esComputadora)}>
                              Cant. Entregada
                            </div>
                          </div>
                          {lsip.detalleSalida.length > 0 &&
                            lsip.detalleSalida.map(dsp => (
                              <div
                                id={dsp.marca.codigo}
                                key={dsp.marca.codigo}
                                style={{
                                  ...estilos.cuerpoitemcuecue(
                                    param.esComputadora
                                  )
                                }}
                              >
                                <div
                                  style={estilos.campo0(param.esComputadora)}
                                >
                                  {dsp.marca.nombre}
                                </div>
                                <div
                                  style={estilos.campo2(param.esComputadora)}
                                >
                                  {dsp.cantidad}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div style={estilos.opcionesBotones(param.esComputadora)}>
                {/* <div>
                    {param.rol !== 'SUPER ADMINISTRADOR' &&
                      <Boton
                        style={{
                          backgroundColor: '#2D8B0B',
                          borderRadius: param.esComputadora ? '0.5vw' : '1vw',
                          padding: param.esComputadora ? '0.8vw' : '2vw'
                        }}
                        textoStyle={{
                          color: 'white',
                          width: '100%',
                          fontSize: param.esComputadora ? '1vw' : '2.5vw'
                        }}
                        texto={
                          param.rol === 'SUPER ADMINISTRADOR'
                            ? 'Aprobar Pedido'
                            : 'Finalizar Ingreso'
                        }
                        tipo='submit'
                        tonalidad='20'
                        estaProcesando={false}
                        onClick={async () => {
                          
                        }}
                      />
                      }
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MDetalleListaSalida
