import React, { useState, useEffect, useRef, useCallback } from 'react'
import * as estilos from './MAgregarPreventistaCSS'
import Boton from '../../../../../../Elementos/Boton'
import Input from '../../../../../../Elementos/Input'
import Opciones from '../../../../../../Elementos/Opciones'
import * as api from '../../../../../../Api/Api'
import * as fg from '../../../../../../Api/FuncionesGlobales'

function MAgregarEmpresa (param) {
  const [estaProcesando, setestaProcesando] = useState(false)
  const [estadoOpcion, setEstadoOpcion] = useState(
    (function () {
      if (param.tipo === 'editar') {
        if (Object.keys(param.param['productos']).length !== 1) {
          return 'NULL'
        }
      }
    })()
  )
  const [propietarioOp, setPropietarioOp] = useState([
    { codigo: "S", dsc: 'Si' },
    { codigo: "N", dsc: 'No' }
  ])

  const [tdocop, settdocop] = useState(null)
  const [empop, setempop] = useState(null)

  const [rolin, setrolin] = useState('')
  const [nombreIn, setNombreIn] = useState('')
  const [apemIn, setapemin] = useState('')
  const [apepin, setapepin] = useState('')
  const [tdocin, settdocin] = useState('')
  const [ndocin, setndocin] = useState('')
  const [empin, setempin] = useState('')
  const [ncelin, setncelin] = useState('')
  const [emailin, setemailin] = useState('')
  const [habilitadoIn, setHabilitadoIn] = useState('')
  const [envioForm, setEnvioForm] = useState(false)
  const [codigoElem, setCodigoElem] = useState(null)

  useEffect(() => {
    console.log(param)
    const inicio=async ()=>{
      const tdocCall = await api.listarTiposDocumentos();
      const empCall = await api.listarEmpresas(null,'S',null)
      const rolCall = await api.listarRoles()
      
      if(tdocCall && empCall && rolCall){
        if(tdocCall.SMListaErrores.length === 0 &&
          empCall.SMListaErrores.length === 0 &&
          rolCall.SMListaErrores.length === 0
        ){
          settdocop(tdocCall.tipoDocumento)
          setempop(empCall.empresa)
          setrolin(rolCall.roles.find(rp => rp.codigo === "PRVT01").id)
        }
        
      }
    } 
    inicio()
    if(param.codigoElem !== null){
      
    }
    
  }, [])

  const cambiarEstadoOpcion = event => {
    setEstadoOpcion(event.target.value)
    setHabilitadoIn(event.target.value)
  }
  return (
    <>
      <div id='baseModal' style={estilos.baseModal(param.esComputadora)}>
        <div
          id='contenedorModal'
          style={estilos.contenedorModal(param.esComputadora)}
        >
          <div style={estilos.botonCerrarModalStyle(param.esComputadora)}>
            <Boton
              style={{
                backgroundColor: '#E12121',
                borderRadius: param.esComputadora ? '0.5vw' : '1vw',
                padding: param.esComputadora ? '0.8vw' : '2vw'
              }}
              textoStyle={{
                color: 'white',
                width: '100%',
                fontSize: param.esComputadora ? '1vw' : '2.5vw'
              }}
              texto='X'
              tipo='submit'
              tonalidad='20'
              estaProcesando={estaProcesando}
              onClick={() => {
                param.cerrarModal()
              }}
            />
          </div>
          <div style={estilos.tituloCSS(param.esComputadora)}>
            {param.tipoAccion === 'agregarEmpresa'
              ? 'Agregar Preventista'
              : 'Editar Empresa'}
          </div>
          <div
            id='contenidoModal'
            style={estilos.contenidoModal(param.esComputadora)}
          >
            <div style={estilos.formularioCSS(param.esComputadora)}>
              <div style={estilos.campo1(param.esComputadora)}>
                <Input
                  style={{
                    ...(envioForm &&
                      estilos.estiloVacio(param.esComputadora, nombreIn))
                  }}
                  textoStyle={{}}
                  id='nombres'
                  moderno={'Nombres'}
                  tipo='text'
                  onChange={e => {
                    setNombreIn(e.currentTarget.value)
                  }}
                  valorInicial={nombreIn !== '' ? nombreIn : ''}
                  esComputadora={param.esComputadora}
                />
              </div>
              <div style={estilos.campo3(param.esComputadora)}>
                <Input
                  style={{
                    ...(envioForm &&
                      estilos.estiloVacio(param.esComputadora, apepin))
                  }}
                  textoStyle={{}}
                  id='apellidosp'
                  moderno={'Apellido Paterno'}
                  tipo='text'
                  onChange={e => {
                    setapepin(e.currentTarget.value)
                  }}
                  valorInicial={apepin !== '' ? apepin : ''}
                  esComputadora={param.esComputadora}
                />
              </div>
              <div style={estilos.campo4(param.esComputadora)}>
                <Input
                  style={{
                    ...(envioForm &&
                      estilos.estiloVacio(param.esComputadora, apemIn))
                  }}
                  textoStyle={{}}
                  id='apellidosm'
                  moderno={'Apellido Materno'}
                  tipo='text'
                  onChange={e => {
                    setapemin(e.currentTarget.value)
                  }}
                  valorInicial={apemIn !== '' ? apemIn : ''}
                  esComputadora={param.esComputadora}
                />
              </div>
              <div style={estilos.campo5(param.esComputadora)}>
              <Opciones
                  style = {{...(envioForm && estilos.estiloVacio(param.esComputadora,tdocin))}}
                  id='tdoc'
                  alto='55px'
                  moderno={'Tipo Documento'}
                  opciones={tdocop !== null ? tdocop : []}
                  clave='codigo'
                  valor={['descripcion']}
                  esComputadora={param.esComputadora}
                  onClick={e => {
                    settdocin(e)
                  }}
                  programaOrigen='agregarEditarProductos'
                  valorInicial = {param.codigo !==null ? false:false}
                />
              </div>
              <div style={estilos.campo6(param.esComputadora)}>
                <Input
                  style={{
                    ...(envioForm &&
                      estilos.estiloVacio(param.esComputadora, ndocin))
                  }}
                  textoStyle={{}}
                  id='ndoc'
                  moderno={'Nro. Documento'}
                  tipo='number'
                  maxDigitos = "15"
                  onChange={e => {
                    setndocin(e.currentTarget.value)
                  }}
                  valorInicial={ndocin !== '' ? ndocin : ''}
                  esComputadora={param.esComputadora}
                />
              </div>
              <div style={estilos.campo7(param.esComputadora)}>
                <Opciones
                  style = {{...(envioForm && estilos.estiloVacio(param.esComputadora,'algo'))}}
                  id='emp'
                  alto='55px'
                  moderno={'Empresa'}
                  opciones={empop !== null ? empop : []}
                  clave='codigo'
                  valor={['nombre']}
                  esComputadora={param.esComputadora}
                  onClick={e => {
                    setempin(e)
                  }}
                  programaOrigen='agregarEditarProductos'
                  valorInicial = {param.codigo !==null ? false:false}
                />
              </div>
              <div style={estilos.campo8(param.esComputadora)}>
                <Input
                  style={{
                    ...(envioForm &&
                      estilos.estiloVacio(param.esComputadora, ncelin))
                  }}
                  textoStyle={{}}
                  id='ncel'
                  moderno={'Nro. Celular'}
                  tipo='number'
                  maxDigitos='9'
                  onChange={e => {
                    setncelin(e.currentTarget.value)
                  }}
                  valorInicial={ncelin !== '' ? ncelin : ''}
                  esComputadora={param.esComputadora}
                />
              </div>
              <div style={estilos.campo9(param.esComputadora)}>
                <Input
                  style={{
                    ...(envioForm &&
                      estilos.estiloVacio(param.esComputadora, 'algo'))
                  }}
                  textoStyle={{}}
                  id='nombres'
                  moderno={'Email'}
                  tipo='text'
                  onChange={e => {
                    setemailin(e.currentTarget.value)
                  }}
                  valorInicial={emailin !== '' ? emailin : ''}
                  esComputadora={param.esComputadora}
                />
              </div>
              <div style={estilos.campo10(param.esComputadora)}>
                <div
                  style={{
                    width: '100%',
                    backgroundColor: 'white',
                    border: '0.1vw solid #cccccc',
                    borderRadius: param.esComputadora ? '0.3vw' : '1vw',
                    ...(envioForm && estilos.estiloVacio(param.esComputadora,habilitadoIn))
                  }}
                >
                  <div>
                    <input
                      type='radio'
                      id='S'
                      name='opciones'
                      value='S'
                      checked={estadoOpcion === 'S'}
                      onChange={cambiarEstadoOpcion}
                    />
                    <label htmlFor='S'>Habilitado</label>
                  </div>
                  <div>
                    <input
                      type='radio'
                      id='N'
                      name='opciones'
                      value='N'
                      checked={estadoOpcion === 'N'}
                      onChange={cambiarEstadoOpcion}
                    />
                    <label htmlFor='N'>Deshabilitado</label>
                  </div>
                </div>
              </div>
              <div style={estilos.confirmar(param.esComputadora)}>
                <Boton
                  style={{
                    backgroundColor: '#2D8B0B',
                    borderRadius: param.esComputadora ? '0.5vw' : '1vw',
                    padding: param.esComputadora ? '0.8vw' : '2vw'
                  }}
                  textoStyle={{
                    color: 'white',
                    width: '100%',
                    fontSize: param.esComputadora ? '1vw' : '2.5vw'
                  }}
                  texto='Confirmar'
                  tipo='submit'
                  estaProcesando={false}
                  onClick={async event => {
                    event.preventDefault()
                    setEnvioForm(true)
                    if (
                      nombreIn !== '' &&
                      apemIn !== '' &&
                      apepin !== '' &&
                      tdocin !== '' &&
                      ndocin !== '' &&
                      //empin !== '' &&
                      ncelin !== '' &&
                      //emailin !== '' &&
                      habilitadoIn !== ''
                    ) {
                      const persona = new api.Persona(
                        ndocin,
                        nombreIn,
                        apepin,
                        apemIn,
                        tdocin,
                        '0001-01-01',
                        '00000',
                        '',
                        emailin, // correoElectronico
                        ncelin,
                        '',
                        'recibo.jpg'
                      )
                      
                      const regPerCall = await api.crearPersona(
                        empin === '' ? 0:empin,
                        1,
                        persona,
                        parseInt(rolin),
                        '',
                      )
                      if(regPerCall){
                        if(regPerCall.SMListaErrores.length === 0){
                          param.cerrarModal()
                        }
                        else{
                          console.log(regPerCall)
                        }
                      }
                    }
                  }}
                />
              </div>
              <div style={estilos.cancelar(param.esComputadora)}>
                <Boton
                  style={{
                    backgroundColor: '#E12121',
                    borderRadius: param.esComputadora ? '0.5vw' : '1vw',
                    padding: param.esComputadora ? '0.8vw' : '2vw'
                  }}
                  textoStyle={{
                    color: 'white',
                    width: '100%',
                    fontSize: param.esComputadora ? '1vw' : '2.5vw'
                  }}
                  texto='Cancelar'
                  tipo='submit'
                  estaProcesando={false}
                  onClick={event => {
                    event.preventDefault()
                    param.cerrarModal()
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MAgregarEmpresa
