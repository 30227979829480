import React, { useState, useEffect, useRef, useCallback } from 'react'
import * as estilos from './MAgregarMarcaCSS'
import Boton from '../../../../../../Elementos/Boton'
import Input from '../../../../../../Elementos/Input'
import Opciones from '../../../../../../Elementos/Opciones'
import * as api from '../../../../../../Api/Api'
import * as fg from '../../../../../../Api/FuncionesGlobales'

function MAgregarMarca (param) {
  const [estaProcesando, setestaProcesando] = useState(false)
  const [estadoOpcion, setEstadoOpcion] = useState(
    (function () {
      if (param.tipo === 'editar') {
        if (Object.keys(param.param['productos']).length !== 1) {
          return 'NULL'
        }
      }
    })()
  )
  const [empresasOp, setempresasOp] = useState(null)
  const [nombreIn, setNombreIn] = useState('')
  const [rucIn, setRucIn] = useState('')
  const [empresaIn, setempresaIn] = useState('')
  const [habilitadoIn, setHabilitadoIn] = useState('')
  const [envioForm, setEnvioForm] = useState(false)
  const [codigoElem, setCodigoElem] = useState(null)

  useEffect(() => {
    console.log(param)
    
    const empresas = async () => {
      const respuesta2 = await api.listarEmpresas(null, null, 'N')
      if (respuesta2)
        if (respuesta2.SMListaErrores.length === 0) {
          setempresasOp(respuesta2.empresa)
        }
    }
    
    if (param.codigoElem !== null) {
      const obtenerEmpresa = async () => {
        await empresas()
        const respuesta = await api.listarMarcas(param.codigoElem, '', '')
        
        if (respuesta) {
          setNombreIn(respuesta.marca.nombre)
          setHabilitadoIn(respuesta.marca.activo)
          setEstadoOpcion(respuesta.marca.activo)
          setCodigoElem(param.codigoElem)
          setempresaIn(respuesta.marca.empresa.codigo)
          const elementoSeleccionado = document.getElementById('propioEmp')
          if(elementoSeleccionado !== null){
            elementoSeleccionado.value = respuesta.marca.empresa.codigo
          }
        }
      }
      obtenerEmpresa()
    }else{
      empresas()
    }
  }, [])

  const cambiarEstadoOpcion = event => {
    setEstadoOpcion(event.target.value)
    setHabilitadoIn(event.target.value)
  }
  return (
    <>
      <div id='baseModal' style={estilos.baseModal(param.esComputadora)}>
        <div
          id='contenedorModal'
          style={estilos.contenedorModal(param.esComputadora)}
        >
          <div style={estilos.botonCerrarModalStyle(param.esComputadora)}>
            <Boton
              style={{
                backgroundColor: '#E12121',
                borderRadius: param.esComputadora ? '0.5vw' : '1vw',
                padding: param.esComputadora ? '0.8vw' : '2vw'
              }}
              textoStyle={{
                color: 'white',
                width: '100%',
                fontSize: param.esComputadora ? '1vw' : '2.5vw'
              }}
              texto='X'
              tipo='submit'
              tonalidad='20'
              estaProcesando={estaProcesando}
              onClick={() => {
                param.cerrarModal()
              }}
            />
          </div>
          <div style={estilos.tituloCSS(param.esComputadora)}>
            {param.tipoAccion === 'agregarEmpresa'
              ? 'Agregar Marca'
              : 'Editar Marca'}
          </div>
          <div
            id='contenidoModal'
            style={estilos.contenidoModal(param.esComputadora)}
          >
            <div style={estilos.formularioCSS(param.esComputadora)}>
              <div style={estilos.nombre(param.esComputadora)}>
                <Input
                  style={{
                    ...(envioForm &&
                      estilos.estiloVacio(param.esComputadora, nombreIn))
                  }}
                  textoStyle={{}}
                  id='nombres'
                  moderno={'Nombre'}
                  tipo='text'
                  onChange={e => {
                    setNombreIn(e.currentTarget.value)
                  }}
                  valorInicial={nombreIn !== '' ? nombreIn : ''}
                  esComputadora={param.esComputadora}
                />
              </div>
              
              <div style={estilos.empresa(param.esComputadora)}>
                <Opciones
                  style={{
                    ...(envioForm &&
                      estilos.estiloVacio(param.esComputadora, empresaIn))
                  }}
                  id='propioEmp'
                  alto='55px'
                  moderno={'Empresa'}
                  opciones={empresasOp !== null ? empresasOp : []}
                  clave='codigo'
                  valor={['nombre']}
                  esComputadora={param.esComputadora}
                  onClick={e => {
                    setempresaIn(e)
                  }}
                  programaOrigen='agregarEditarProductos'
                  valorInicial={param.codigo !== null && param.codigo!=='' ? true : false}
                />
              </div>
              <div style={estilos.activo(param.esComputadora)}>
                <div
                  style={{
                    width: '100%',
                    backgroundColor: 'white',
                    border: '0.1vw solid #cccccc',
                    borderRadius: param.esComputadora ? '0.3vw' : '1vw',
                    ...(envioForm &&
                      estilos.estiloVacio(param.esComputadora, habilitadoIn))
                  }}
                >
                  <div>
                    <input
                      type='radio'
                      id='S'
                      name='opciones'
                      value='S'
                      checked={estadoOpcion === 'S'}
                      onChange={cambiarEstadoOpcion}
                    />
                    <label htmlFor='S'>Habilitado</label>
                  </div>
                  <div>
                    <input
                      type='radio'
                      id='N'
                      name='opciones'
                      value='N'
                      checked={estadoOpcion === 'N'}
                      onChange={cambiarEstadoOpcion}
                    />
                    <label htmlFor='N'>Deshabilitado</label>
                  </div>
                </div>
              </div>
              <div style={estilos.confirmar(param.esComputadora)}>
                <Boton
                  style={{
                    backgroundColor: '#2D8B0B',
                    borderRadius: param.esComputadora ? '0.5vw' : '1vw',
                    padding: param.esComputadora ? '0.8vw' : '2vw'
                  }}
                  textoStyle={{
                    color: 'white',
                    width: '100%',
                    fontSize: param.esComputadora ? '1vw' : '2.5vw'
                  }}
                  texto='Confirmar'
                  tipo='submit'
                  estaProcesando={false}
                  onClick={async event => {
                    event.preventDefault()
                    setEnvioForm(true)
                    if (
                      nombreIn !== '' &&
                      empresaIn !== '' &&
                      habilitadoIn !== ''
                    ) {
                      console.log(codigoElem)
                      const agregarMarcaCall = await api.registroMarca(
                        codigoElem,
                        nombreIn,
                        parseInt(empresaIn, 10),
                        habilitadoIn,
                        'N'
                      )
                      if (agregarMarcaCall) {
                        console.log(empresaIn)
                        console.log(agregarMarcaCall)
                        param.cerrarModal()
                      }
                    }
                  }}
                />
              </div>
              <div style={estilos.cancelar(param.esComputadora)}>
                <Boton
                  style={{
                    backgroundColor: '#E12121',
                    borderRadius: param.esComputadora ? '0.5vw' : '1vw',
                    padding: param.esComputadora ? '0.8vw' : '2vw'
                  }}
                  textoStyle={{
                    color: 'white',
                    width: '100%',
                    fontSize: param.esComputadora ? '1vw' : '2.5vw'
                  }}
                  texto='Cancelar'
                  tipo='submit'
                  estaProcesando={false}
                  onClick={event => {
                    event.preventDefault()
                    param.cerrarModal()
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MAgregarMarca
