import React, { useState, useEffect, useRef, useCallback } from 'react'
import * as estilos from './MEliminarPreventistaCSS'
import Boton from '../../../../../../Elementos/Boton'
import Input from '../../../../../../Elementos/Input'
import Opciones from '../../../../../../Elementos/Opciones'
import * as api from '../../../../../../Api/Api'
import * as fg from '../../../../../../Api/FuncionesGlobales'

function MAgregarEmpresa (param) {
  const [estaProcesando, setestaProcesando] = useState(false)
  const [estadoOpcion, setEstadoOpcion] = useState(
    (function () {
      if (param.tipo === 'editar') {
        if (Object.keys(param.param['productos']).length !== 1) {
          return 'NULL'
        }
      }
    })()
  )
  const [propietarioOp, setPropietarioOp] = useState([
    { codigo: 'S', dsc: 'Si' },
    { codigo: 'N', dsc: 'No' }
  ])
  const [nombreIn, setNombreIn] = useState('')
  const [rucIn, setRucIn] = useState('')
  const [propioIn, setPropioIn] = useState('')
  const [habilitadoIn, setHabilitadoIn] = useState('')
  const [envioForm, setEnvioForm] = useState(false)
  const [codigoElem, setCodigoElem] = useState(null)

  useEffect(() => {
    const inicio = async () => {
      setCodigoElem(param.codigoElem)
    }
    inicio()
  }, [])

  return (
    <>
      <div id='baseModal' style={estilos.baseModal(param.esComputadora)}>
        <div
          id='contenedorModal'
          style={estilos.contenedorModal(param.esComputadora)}
        >
          <div style={estilos.botonCerrarModalStyle(param.esComputadora)}>
            <Boton
              style={{
                backgroundColor: '#E12121',
                borderRadius: param.esComputadora ? '0.5vw' : '1vw',
                padding: param.esComputadora ? '0.8vw' : '2vw'
              }}
              textoStyle={{
                color: 'white',
                width: '100%',
                fontSize: param.esComputadora ? '1vw' : '2.5vw'
              }}
              texto='X'
              tipo='submit'
              tonalidad='20'
              estaProcesando={estaProcesando}
              onClick={() => {
                param.cerrarModal()
              }}
            />
          </div>
          <div style={estilos.tituloCSS(param.esComputadora)}>
            Eliminar Empresa
          </div>
          <div
            id='contenidoModal'
            style={estilos.contenidoModal(param.esComputadora)}
          >
            <div style={estilos.formularioCSS(param.esComputadora)}>
              <div style={estilos.pregunta(param.esComputadora)}>
                ¿Seguro que desea eliminar la empresa?
              </div>
              <div style={estilos.nombre(param.esComputadora)}>
                {param.dscElemSelec}
              </div>
              <div style={estilos.confirmar(param.esComputadora)}>
                <Boton
                  style={{
                    backgroundColor: '#2D8B0B',
                    borderRadius: param.esComputadora ? '0.5vw' : '1vw',
                    padding: param.esComputadora ? '0.8vw' : '2vw'
                  }}
                  textoStyle={{
                    color: 'white',
                    width: '100%',
                    fontSize: param.esComputadora ? '1vw' : '2.5vw'
                  }}
                  texto='Confirmar'
                  tipo='submit'
                  estaProcesando={false}
                  onClick={async event => {
                    event.preventDefault()
                    setEnvioForm(true)
                    console.log(codigoElem)
                    const agregarEmpresaCall = await api.registroEmpresa(
                      codigoElem,
                      nombreIn,
                      parseInt(rucIn, 10),
                      habilitadoIn,
                      propioIn,
                      'S'
                    )
                    if (agregarEmpresaCall) {
                      console.log(propioIn)
                      console.log(agregarEmpresaCall)
                      param.cerrarModal()
                    }
                  }}
                />
              </div>
              <div style={estilos.cancelar(param.esComputadora)}>
                <Boton
                  style={{
                    backgroundColor: '#E12121',
                    borderRadius: param.esComputadora ? '0.5vw' : '1vw',
                    padding: param.esComputadora ? '0.8vw' : '2vw'
                  }}
                  textoStyle={{
                    color: 'white',
                    width: '100%',
                    fontSize: param.esComputadora ? '1vw' : '2.5vw'
                  }}
                  texto='Cancelar'
                  tipo='submit'
                  estaProcesando={false}
                  onClick={event => {
                    event.preventDefault()
                    param.cerrarModal()
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MAgregarEmpresa
