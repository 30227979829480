import React, { useState, useEffect, useContext } from 'react'
import { Contexto } from '../../../index'
import * as estilos from './StockAlmacenCSS'
import Input from '../../../../../Elementos/Input'
import Opciones from '../../../../../Elementos/Opciones'
import Boton from '../../../../../Elementos/Boton'
import * as api from '../../../../../Api/Api'
import * as fg from '../../../../../Api/FuncionesGlobales'

function StockAlmacen (param) {
  const { esComputadora, rol } = useContext(Contexto)
  const [empop, setempop] = useState(null)
  const [prevop, setprevop] = useState(null)

  const [previn, setprevin] = useState('')
  const [empin, setempin] = useState('')
  const [pedidoinfo, setpedidoinfo] = useState(null)

  const [liststock, setliststock] = useState([])

  useEffect(() => {
    const inicio = async () => {
      const stockCall = await api.listarInsumos(
        null,'S',null,null,null,null,'S'
      )
      console.log(stockCall)
      setliststock(stockCall.insumo)
      const empcall = await api.listarEmpresas(null, 'S', 'N')
      const prevcall = await api.listarPreventistas(0, 'S')
      if (empcall && prevcall) {
        if (
          empcall.SMListaErrores.length === 0 &&
          prevcall.SMListaErrores.length === 0
        ) {
          setempop(empcall.empresa)
          setprevop(prevcall.preventistas)
        }
      }
    }
    inicio()
  }, [])
  const salgodeinput = async e => {
    let codmarca = parseInt(e.currentTarget.parentNode.parentNode.parentNode.id)
    let valor =
      e.currentTarget.value === '' ? 0 : parseInt(e.currentTarget.value)

    
  }
  useEffect(() => {
    console.log(empin, previn)
    const actualizar = async () => {
      if (empin !== '') {
        const prevdom = document.getElementById('previn')
        if (prevdom) prevdom.value = ''
        setprevin('')
        const listapedidosact = await api.listarInsumos(
          null,'S',null,parseInt(empin),null,null,"S"
        )
        // const listapedidosact = await api.pedidoInsumoDetalle(
        //   null,
        //   parseInt(empin),
        //   '0'
        // )
        if (listapedidosact) {
          if (listapedidosact.SMListaErrores.length === 0) {
            setliststock(listapedidosact.insumo)
            // setpedidoinfo(listapedidosact.pedido)
            // setliststock(listapedidosact.insumosPedidoJson)
            //console.log(listapedidosact.insumosPedidoJson)
          }
        }
      }
      if (previn !== '') {
        const empdom = document.getElementById('empin')
        if (empdom) empdom.value = ''
        setempin('')
        const listapedidosact = await api.listarInsumos(
          null,'S',null,null,null,previn,"S"
        )
        //const listapedidosact = await api.pedidoInsumoDetalle(null, 0, previn)
        
        if (listapedidosact) {
          if (listapedidosact.SMListaErrores.length === 0) {
            setliststock(listapedidosact.insumo)
            // setpedidoinfo(listapedidosact.pedido)
            // setliststock(listapedidosact.insumosPedidoJson)
          }
        }
      }
      if (empin === '' && previn === '') {
        const stockCall = await api.listarInsumos(
          null,'S',null,null,null,null,'S'
        )
        // const prevdom = document.getElementById('previn')
        // if (prevdom) prevdom.value = ''
        // const empdom = document.getElementById('empin')
        // if (empdom) empdom.value = ''
        // setliststock([])
        if (stockCall) {
          if (stockCall.SMListaErrores.length === 0) {
            setliststock(stockCall.insumo)
            // setpedidoinfo(listapedidosact.pedido)
            // setliststock(listapedidosact.insumosPedidoJson)
          }
        }
      }
    }
    actualizar()
  }, [empin, previn])

  return (
    <>
      <div style={estilos.cabeceraVista(esComputadora)}>
        {'Stock de Almacén'}
      </div>
      <div style={estilos.cabeceraFiltros(esComputadora)}>
        <div style={estilos.campof1(esComputadora)}>
          <Opciones
            style={
              {
                /*...(envioForm &&
              estilos.estiloVacio(esComputadora, ''))*/
              }
            }
            id='empin'
            moderno={'Empresas'}
            opciones={empop !== null ? empop : []}
            clave='codigo'
            valor={['nombre']}
            esComputadora={esComputadora}
            onClick={e => {
              setempin(e)
              setprevin('')
            }}
            programaOrigen='agregarEditarProductos'
            valorInicial={empin !== '' ? true : false}
          />
        </div>
        <div style={estilos.campof2(esComputadora)}>
          <Opciones
            style={
              {
                /*...(envioForm &&
              estilos.estiloVacio(esComputadora, ''))*/
              }
            }
            id='previn'
            moderno={'Preventistas'}
            opciones={prevop !== null ? prevop : []}
            clave='usuario'
            valor={['nombre', 'apellido_paterno', 'apellido_materno']}
            esComputadora={esComputadora}
            onClick={e => {
              setprevin(e)
              setempin('')
            }}
            programaOrigen='agregarEditarProductos'
            valorInicial={previn !== '' ? true : false}
          />
        </div>
      </div>
      <div style={estilos.cuerpo(esComputadora)}>
        
        <div id='contitem' style={estilos.contenidoItems(esComputadora)}>
          {liststock.length > 0 &&
            liststock.map(lp => (
              <div
                id={lp.insumo.codigo}
                key={lp.insumo.codigo}
                style={estilos.cuerpoitem(esComputadora)}
              >
                <div style={estilos.cuerpoitemcab(esComputadora)}>
                  {lp.insumo.nombre +
                    ' (' +
                    lp.insumo.unidades +
                    ' ' +
                    lp.insumo.unidad_medida +
                    ')'}
                </div>
                <div style={estilos.cuerpoitemcue(esComputadora)}>
                  <div
                    style={{
                      ...estilos.cuerpoitemcuecab(esComputadora),
                      fontWeight: 'bolder'
                    }}
                  >
                    <div style={estilos.campo0(esComputadora)}>
                      Marca
                    </div>
                    <div style={estilos.campo1(esComputadora)}>
                      Empresa
                    </div>
                    <div style={estilos.campo2(esComputadora)}>
                      Preventistas
                    </div>
                    <div style={estilos.campo3(esComputadora)}>
                      Stock Minimo
                    </div>
                    <div style={estilos.campo4(esComputadora)}>
                      Stock
                    </div>
                    
                    {/* <div style={estilos.campo5(esComputadora)}>
                      Ajuste
                    </div> */}
                  </div>
                  {lp.preventistas.length > 0 &&
                    lp.preventistas.map(dp => (
                      <div
                        id={dp.marca.codigo}
                        key={dp.marca.codigo}
                        style={{
                          ...estilos.cuerpoitemcuecue(esComputadora)
                          //height: '80%'
                        }}
                      >
                        <div style={estilos.campo0(esComputadora)}>
                          {dp.marca.nombre}
                        </div>
                        <div style={estilos.campo1(esComputadora)}>
                          {dp.empresa.nombre}
                        </div>
                        <div style={estilos.campo2(esComputadora)}>
                          {fg.capitalizar_palabra(dp.preventistas.nombre)}
                        </div>
                        <div style={estilos.campo3(esComputadora)}>
                          {dp.stock_minimo}
                        </div>
                        <div style={estilos.campo4(esComputadora)}>
                          {dp.stock}
                        </div>

                        {/* <div style={estilos.campo5(esComputadora)}>
                          <Input
                            style={{
                              fontWeight: 'bolder',
                              fontSize: esComputadora ? '1.2vw' : '2.5vw',
                              padding: esComputadora ? '0.2vw' : '0.7vw',
                              width: '100%'
                            }}
                            textoStyle={{
                              fontWeight: 'bolder',
                              fontSize: esComputadora ? '1vw' : '2.5vw'
                            }}
                            id='inputin'
                            moderno=''
                            tipo='number'
                            maxDigitos='10'
                            autoComplete
                            onChange={e => {
                              if (e.currentTarget.value === '') {
                                salgodeinput(e)
                              }
                            }}
                            onBlur={salgodeinput}
                            valorInicial={''}
                            esComputadora={esComputadora}
                          />
                        </div> */}
                      </div>
                    ))}
                </div>
              </div>
            ))}
        </div>
      </div>
      <div style={estilos.opcionesBotones(esComputadora)}>
        {/* <div>
          <Boton
            style={{
              backgroundColor: '#2D8B0B',
              borderRadius: esComputadora ? '0.5vw' : '1vw',
              padding: esComputadora ? '0.8vw' : '2vw'
            }}
            textoStyle={{
              color: 'white',
              width: '100%',
              fontSize: esComputadora ? '1vw' : '2.5vw'
            }}
            texto={
              rol === 'SUPER ADMINISTRADOR'
                ? 'Aprobar Pedido'
                : 'Finalizar Ingreso'
            }
            tipo='submit'
            tonalidad='20'
            estaProcesando={false}
            onClick={async () => {
              
            }}
          />
        </div> */}
      </div>
    </>
  )
}

export default StockAlmacen
