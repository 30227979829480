export const cabeceraVista = esComputadora => ({
  gridArea: '1/1/2/21',
  backgroundColor: '#AD6726',
  fontWeight: 'bolder',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  borderRadius: esComputadora ? '1vw 1vw 0vw 0vw' : '1vw 1vw 0vw 0vw',
  fontSize: esComputadora ? '2vw' : '4vw'
})
export const cabeceraFiltros = esComputadora => ({
  gridArea: '2/1/4/21',
  //backgroundColor: esComputadora ? 'green' : 'green',
  display: 'grid',
  gridTemplateColumns: 'repeat(20,1fr)',
  gridTemplateRows: 'repeat(4,1fr)',
  justifyContent: 'center',
  alignItems: 'center'
})
const campof = () => ({
  padding: '0.1vw'
})

export const campof1 = esComputadora => ({
  gridArea: '1/1/5/11',
  fontSize: esComputadora ? '1vw' : '2vw',
  ...campof()
})
export const campof2 = esComputadora => ({
  gridArea: '1/11/5/21',
  fontSize: esComputadora ? '1vw' : '2vw',
  ...campof()
})
export const cuerpo = esComputadora => ({
  gridArea: '4/1/19/21',
  backgroundColor: "#eeeeee",
  boxShadow: "0.1vw 0.1vw 1vw #aaaaaa",
  padding: "1vw",
  overflow: "auto",
})
export const elemContenido = esComputadora => ({
  backgroundColor: '#FFF7A9',
  width: esComputadora ? '28.7vw' : '100vw',
  height: esComputadora ? '30vh' : '20vh',
  marginBottom: esComputadora ? '' : '1vw',
  display: 'grid',
  gridTemplateColumns: 'repeat(20,1fr)',
  gridTemplateRows: 'repeat(20,1fr)',
  boxSizing: "border-box",
  padding: "1vw",
  border: "0.1vw solid #CCCCCC",
  borderRadius: esComputadora ? '1vw':'2vw',
  boxShadow: esComputadora ? '0.2vw 0.2vw 0.5vw #cccccc':'0.5vw 0.5vw 1vw #cccccc',
 
})
export const elemContenidoCampo = (esComputadora) => ({
    fontSize: esComputadora ? '1vw':'2vw',
    //backgroundColor: "green",
    //padding: '0.5vw',
    boxSizing: 'border-box'
})
export const elemContenidoCampo1 = esComputadora => ({
  gridArea: '1/1/4/9',
  ...elemContenidoCampo(esComputadora)
})
export const elemContenidoCampo2 = esComputadora => ({
    gridArea: '1/12/4/21',
    ...elemContenidoCampo(esComputadora)
})
export const elemContenidoCampo3 = esComputadora => ({
    gridArea: '4/1/7/11',
    ...elemContenidoCampo(esComputadora)
})
export const elemContenidoCampo4 = esComputadora => ({
    gridArea: '7/1/10/11',
    ...elemContenidoCampo(esComputadora)
})
export const elemContenidoCampo5 = esComputadora => ({
    gridArea: '10/1/13/11',
    ...elemContenidoCampo(esComputadora)
})
export const elemContenidoCampo6 = esComputadora => ({
    gridArea: '13/1/16/7',
    ...elemContenidoCampo(esComputadora)
})
export const elemContenidoCampo7 = esComputadora => ({
    gridArea: '13/7/16/14',
    ...elemContenidoCampo(esComputadora)
})
export const elemContenidoCampo8 = esComputadora => ({
    gridArea: '13/14/16/21',
    ...elemContenidoCampo(esComputadora)
})
export const elemContenidoCampo9 = esComputadora => ({
    gridArea: '13/14/16/21',
    ...elemContenidoCampo(esComputadora)
})


export const contenidoItems = (esComputadora)=>({
  display: esComputadora ? 'flex':'',
  //flexDirection: "row",
  //backgroundColor: "green",
  width: "100%",
  flexWrap : 'wrap',
  justifyContent: "center",
  gap: "0px 0.5vw"
})
export const cuerpoitem = (esComputadora) => ({
  marginTop: '1vw',
  width: esComputadora ? '100vw' : '150vw',
  height: esComputadora ? '20vh' : 'auto',
  //backgroundColor: 'blue',
  border: "1px solid #cccccc",
  borderRadius: '1vw',
  //flex: '1 0 calc(33.333% - 0.5vw)'
})
export const cuerpoitemcab = (esComputadora) => ({
  backgroundColor: '#AD6726',
  borderRadius: '1vw 1vw 0 0',
  textAlign: "center",
  color: "white",
  fontWeight: "bolder",
  height: "20%",
  fontSize: esComputadora ? '1.2vw' : '2.5vw'
})
export const cuerpoitemcuecab = (esComputadora) => ({
  display: "grid",
  gridTemplateColumns: "repeat(6,1fr)",
  gridTemplateRows: "repeat(1,1fr)",
  //backgroundColor: "green",
})
export const campo0=(esComputadora)=>({
  gridArea: "1/1/2/2",
  padding: "0.5vw",
  fontSize: esComputadora ? '1vw':'2.5vw',
  color: "#555555",
  textAlign: "center"
})
export const campo1=(esComputadora)=>({
  gridArea: "1/2/2/3",
  padding: "0.5vw",
  fontSize: esComputadora ? '1vw':'2.5vw',
  color: "#555555",
  textAlign: "center"
})
export const campo2=(esComputadora)=>({
  gridArea: "1/3/2/4",
  padding: "0.5vw",
  fontSize: esComputadora ? '1vw':'2.5vw',
  color: "#555555",
  textAlign: "center"
})
export const campo3=(esComputadora)=>({
  gridArea: "1/4/2/5",
  padding: "0.5vw",
  fontSize: esComputadora ? '1vw':'2.5vw',
  color: "#555555",
  textAlign: "center"
})
export const campo4=(esComputadora)=>({
  gridArea: "1/5/2/6",
  padding: "0.5vw",
  fontSize: esComputadora ? '1vw':'2.5vw',
  color: "#555555",
  textAlign: "center"
})
export const campo5=(esComputadora)=>({
  gridArea: "1/6/2/7",
  padding: "0.5vw",
  fontSize: esComputadora ? '1vw':'2.5vw',
  color: "#555555",
  textAlign: "center"
})
export const cuerpoitemcuecue = (esComputadora) => ({
  display: "grid",
  gridTemplateColumns: "repeat(6,1fr)",
  gridTemplateRows: "repeat(1,1fr)",
  //backgroundColor: "blue",
})
export const cuerpoitemcue = (esComputadora) => ({
  backgroundColor: 'white',
  borderRadius: '0 0 1vw 1vw',
  height: "80%",
  width: "100%",
  overflow: "auto"
})
export const opcionesBotones = (esComputadora) =>({
  gridArea: '19/1/21/21',
  backgroundColor: "#ad672633",
  boxShadow: "0.1vw 0.1vw 1vw #aaaaaa",
  padding: "1vw",
  overflow: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
})