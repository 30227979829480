import React, { useState, useEffect, useContext } from 'react'
import { Contexto } from '../../../../../Componentes/Principal/index'
import * as e from './ListaIngresosCSS'
import Input from '../../../../../Elementos/Input'
import Opciones from '../../../../../Elementos/Opciones'
import Boton from '../../../../../Elementos/Boton'
import * as api from '../../../../../Api/Api'
import * as fg from '../../../../../Api/FuncionesGlobales'
import MDetalleListaIngreso from './MDetalleListaIngreso'

function ListaIngresos (param) {
  const { esComputadora,rol } = useContext(Contexto)
  const [empop, setempop] = useState(null)
  const [prevop, setprevop] = useState(null)

  const [pedlist, setpedlist] = useState([])

  const [previn, setprevin] = useState('')
  const [empin, setempin] = useState('')

  const [verModal, setVerModal] = useState(false)
  const [codelemselec, setcodelemselec] = useState('')
  const [dscelemeselec, setdscelemselec] = useState('')

  const cerrarModal = () => {
    setVerModal(false)
  }
  useEffect(() => {
    const inicio = async () => {
      const empcall = await api.listarEmpresas(null, 'S','N')
      const prevcall = await api.listarPreventistas(0, 'S')
      if (empcall && prevcall) {
        if (
          empcall.SMListaErrores.length === 0 &&
          prevcall.SMListaErrores.length === 0
        ) {
          setempop(empcall.empresa)
          setprevop(prevcall.preventistas)
        }
      }

      const listpedinscall = await api.listaPedidoInsumo(0, 0)
      if (listpedinscall) {
        if (listpedinscall.SMListaErrores.length === 0) {
          console.log(listpedinscall.pedidos)
          setpedlist(listpedinscall.pedidos)
        }
      }
    }
    inicio()
  }, [verModal])

  useEffect(() => {
    console.log(empin, previn)
    const actualizar = async () => {
      if (empin !== '') {
        const prevdom = document.getElementById('previn')
        if (prevdom) prevdom.value = ''
        setprevin('')
        const listapedidosact = await api.listaPedidoInsumo(empin, 0)
        console.log(listapedidosact)
        if (listapedidosact) {
          if (listapedidosact.SMListaErrores.length === 0) {
            setpedlist(listapedidosact.pedidos)
          }
        }
      }
      if (previn !== '') {
        const empdom = document.getElementById('empin')
        if (empdom) empdom.value = ''
        setempin('')
        const listapedidosact = await api.listaPedidoInsumo(0, previn)
        console.log(listapedidosact)
        if (listapedidosact) {
          if (listapedidosact.SMListaErrores.length === 0) {
            setpedlist(listapedidosact.pedidos)
          }
        }
      }
      if (empin === '' && previn === '') {
        const listapedidosact = await api.listaPedidoInsumo(0, 0)
        console.log(listapedidosact)
        if (listapedidosact) {
          if (listapedidosact.SMListaErrores.length === 0) {
            setpedlist(listapedidosact.pedidos)
          }
        }
      }
    }
    actualizar()
  }, [empin, previn])

  return (
    <>
      {verModal && (
        <div>
          <MDetalleListaIngreso
            esComputadora={esComputadora}
            cerrarModal={cerrarModal}
            codelemselec={codelemselec}
            dscelemeselec={dscelemeselec}
            rol={rol}
          />
        </div>
      )}
      <div style={e.cabeceraVista(esComputadora)}>
        {'Lista de Pedidos (Insumos)'}
      </div>
      <div style={e.cabeceraFiltros(esComputadora)}>
        <div style={e.campof1(esComputadora)}>
          <Opciones
            style={
              {
                /*...(envioForm &&
              estilos.estiloVacio(param.esComputadora, ''))*/
              }
            }
            id='empin'
            moderno={'Empresas'}
            opciones={empop !== null ? empop : []}
            clave='codigo'
            valor={['nombre']}
            esComputadora={esComputadora}
            onClick={e => {
              setempin(e)
              setprevin('')
            }}
            programaOrigen='agregarEditarProductos'
            valorInicial={empin !== '' ? true : false}
          />
        </div>
        <div style={e.campof2(esComputadora)}>
          <Opciones
            style={
              {
                /*...(envioForm &&
              estilos.estiloVacio(param.esComputadora, ''))*/
              }
            }
            id='previn'
            moderno={'Preventistas'}
            opciones={prevop !== null ? prevop : []}
            clave='usuario'
            valor={['nombre', 'apellido_paterno', 'apellido_materno']}
            esComputadora={esComputadora}
            onClick={e => {
              setprevin(e)
              setempin('')
            }}
            programaOrigen='agregarEditarProductos'
            valorInicial={previn !== '' ? true : false}
          />
        </div>
      </div>
      <div style={e.cuerpo(esComputadora)}>
        {pedlist.length > 0 &&
          pedlist.map(pp => (
            <div
              id={pp.pedido}
              dsc={pp.empresa === 0 ? pp.preventista.nombre : pp.empresa.nombre}
              key={pp.pedido}
              style={{ 
                ...e.elemContenido(esComputadora),
                backgroundColor: pp.estado === 1 ? '#FFD9D9':pp.estado === 2 ? '#FFF7A9': 'white'
              }}
              onClick={e => {
                setcodelemselec(e.currentTarget.id)
                setdscelemselec(e.currentTarget.getAttribute('dsc'))
                setVerModal(true)
              }}
            >
              <div style={e.elemContenidoCampo1(esComputadora)}>
                {pp.empresa === 0 ? pp.preventista.nombre : pp.empresa.nombre}
              </div>
              <div style={e.elemContenidoCampo2(esComputadora)}>
                {'Nro. ' + String(pp.pedido).padStart(8, '0')}
              </div>
              <div style={e.elemContenidoCampo3(esComputadora)}>
                <span style={e.encabezados(esComputadora)}>Solicitante:</span>
                {' ' +
                  fg.capitalizar_palabra(
                    pp.usuario_solicitud.nombre +
                      ' ' +
                      pp.usuario_solicitud.apellido_paterno
                  )}
              </div>
              <div style={e.elemContenidoCampo4(esComputadora)}>
                <span style={e.encabezados(esComputadora)}>Recepcionante:</span>
                {pp.usuario_pedido
                  ? ' '+pp.usuario_pedido.nombre + ' ' + pp.usuario_pedido.nombre
                  : ' -----'}
              </div>
              <div style={e.elemContenidoCampo5(esComputadora)}>
                <span style={e.encabezados(esComputadora)}>Ingreso:</span>
                {pp.usuario_ingreso
                  ? " "+pp.usuario_ingreso.nombre + ' ' + pp.usuario_ingreso.nombre
                  : ' -----'}
              </div>
              <div style={e.elemContenidoCampo6(esComputadora)}>
                <div style={e.fecha(esComputadora)}>F. Solicitud</div>
                <div style={e.fecha_formateada(esComputadora)}>
                  {fg.formatear_fecha_ddmmyyyy_hora12(pp.fecha_solicitud, 'AH')}
                </div>
              </div>
              <div style={e.elemContenidoCampo7(esComputadora)}>
                <div style={e.fecha(esComputadora)}>F. Aprobada</div>
                <div style={e.fecha_formateada(esComputadora)}>
                  {pp.fecha_pedido
                    ? fg.formatear_fecha_ddmmyyyy_hora12(pp.fecha_pedido, 'AH')
                    : '-----------'}
                </div>
              </div>
              <div style={e.elemContenidoCampo8(esComputadora)}>
                <div style={e.fecha(esComputadora)}>F. Ingreso</div>
                <div style={e.fecha_formateada(esComputadora)}>
                  {pp.fecha_ingreso
                    ? fg.formatear_fecha_ddmmyyyy_hora12(pp.fecha_ingreso, 'AH')
                    : '-----------'}
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  )
}

export default ListaIngresos
