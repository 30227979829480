import React, { useState, useEffect, useContext } from 'react'
import { Contexto } from '../../../index'
import * as e from './ListaSalidasCSS'
import Input from '../../../../../Elementos/Input'
import Opciones from '../../../../../Elementos/Opciones'
import Boton from '../../../../../Elementos/Boton'
import * as api from '../../../../../Api/Api'
import * as fg from '../../../../../Api/FuncionesGlobales'
import MDetalleListaSalida from './MDetalleListaSalida'
import { nombre } from '../../InformacionGeneral/Insumos/MEliminarInsumoCSS'

function ListaSalidas (param) {
  const { esComputadora, rol } = useContext(Contexto)
  const [maesop, setmaesop] = useState(null)

  const [sallist, setsallist] = useState([])

  const [maesin, setmaesin] = useState('')
  const [motin, setmotin] = useState('')
  const [verModal, setVerModal] = useState(false)
  const [codelemselec, setcodelemselec] = useState('')
  const [dscelemeselec, setdscelemselec] = useState('')

  const [motlist, setmotlist] = useState(
    rol === 'SUPER ADMINISTRADOR'
      ? [
          { cod: 1, dsc: 'PRODUCCIÓN' },
          { cod: 2, dsc: 'TRASLADO' },
          { cod: 3, dsc: 'REAJUSTE' }
        ]
      : [
          { cod: 1, dsc: 'PRODUCCIÓN' },
          { cod: 2, dsc: 'TRASLADO' }
        ]
  )

  const cerrarModal = () => {
    setVerModal(false)
  }
  const [nombremoti, setnombremoti] = useState('')
  const [nombremaestro, setnombremaestro] = useState('')
  useEffect(() => {
    const inicio = async () => {
      const maestrosCall = await api.listarMaestros(null, 'S')
      if (maestrosCall) {
        if (maestrosCall.SMListaErrores.length === 0) {
          setmaesop(maestrosCall.maestro)
        }
      }
      //console.log(maestrosCall)

      const listpedinscall = await api.listaPedidoInsumo(0, 0)
      if (listpedinscall) {
        if (listpedinscall.SMListaErrores.length === 0) {
          //setsallist(listpedinscall.pedidos)
        }
      }
    }
    inicio()
  }, [verModal])

  useEffect(() => {
    console.log(motin, maesin)
    const inicio = async () => {
      const listSalInsCall = await api.listaSalidaInsumo(
        parseInt(motin),
        maesin
      )
      console.log(listSalInsCall)
      if (listSalInsCall) {
        if (listSalInsCall.SMListaErrores.length === 0) {
          setsallist(listSalInsCall.salidas)
        } else {
          console.log('algo salió mal')
        }
      }
    }
    inicio()
  }, [motin, maesin])

  return (
    <>
      {verModal && (
        <div>
          <MDetalleListaSalida
            esComputadora={esComputadora}
            cerrarModal={cerrarModal}
            codelemselec={codelemselec}
            codelemselec2={motin}
            codelemselec3={maesin}
            motivo={nombremoti}
            maestro={nombremaestro}
            rol={rol}
          />
        </div>
      )}
      <div style={e.cabeceraVista(esComputadora)}>
        {'Lista de Salidas (Insumos)'}
      </div>
      <div style={e.cabeceraFiltros(esComputadora)}>
        <div style={e.campof1(esComputadora)}>
          <Opciones
            style={
              {
                /*...(envioForm &&
              estilos.estiloVacio(param.esComputadora, ''))*/
              }
            }
            id='empin'
            moderno={'Motivo'}
            opciones={motlist !== null ? motlist : []}
            clave='cod'
            valor={['dsc']}
            esComputadora={esComputadora}
            onClick={e => {
              setmaesin('')
              setmotin(e)
            }}
            programaOrigen='agregarEditarProductos'
            valorInicial={'' !== '' ? true : false}
          />
        </div>
        {motin === '1' && (
          <div style={e.campof2(esComputadora)}>
            <Opciones
              style={
                {
                  /*...(envioForm &&
              estilos.estiloVacio(param.esComputadora, ''))*/
                }
              }
              id='previn'
              moderno={'Maestros'}
              opciones={maesop !== null ? maesop : []}
              clave='usuario'
              valor={['nombre', 'apellido_paterno', 'apellido_materno']}
              esComputadora={esComputadora}
              onClick={e => {
                console.log(e)
                setmaesin(e)
              }}
              programaOrigen='agregarEditarProductos'
              valorInicial={'' !== '' ? true : false}
            />
          </div>
        )}
      </div>
      <div style={e.cuerpo(esComputadora)}>
        {console.log(sallist.length)}
        {sallist.length > 0 &&
          sallist.map(pp => (
            <div
              id={pp.salida}
              nombres={fg.capitalizar_palabra(
                motlist.filter(mt => mt.cod === pp.motivo)[0].dsc
              )}
              //dsc={pp.empresa === 0 ? pp.preventista.nombre : pp.empresa.nombre}
              key={pp.salida}
              style={{
                ...e.elemContenido(esComputadora),
                backgroundColor:
                  pp.estado === 1
                    ? '#FFD9D9'
                    : pp.estado === 2
                    ? '#FFF7A9'
                    : 'white'
              }}
              onClick={e => {
                setnombremaestro(pp.maestro !== null ? pp.maestro.nombre+' '+pp.maestro.apellido_paterno:'')
                setnombremoti(e.currentTarget.getAttribute('nombres'))
                setcodelemselec(e.currentTarget.id)
                setdscelemselec(e.currentTarget.getAttribute('dsc'))
                setVerModal(true)
              }}
            >
              <div style={e.elemContenidoCampo1(esComputadora)}>
                {fg.capitalizar_palabra(
                  motlist.filter(mt => mt.cod === pp.motivo)[0].dsc
                ) +
                  ' ' +
                  fg.capitalizar_palabra(
                    pp.maestro !== null
                      ? pp.maestro.nombre +
                          ' ' +
                          pp.maestro.apellido_paterno +
                          ' ' +
                          pp.maestro.apellido_materno
                      : ''
                  )}
              </div>
              <div style={e.elemContenidoCampo2(esComputadora)}>
                {'Nro. ' + String(pp.salida).padStart(8, '0')}
              </div>
              <div style={e.elemContenidoCampo3(esComputadora)}>
                <span style={e.encabezados(esComputadora)}>Almacenero:</span>
                {' ' +
                  fg.capitalizar_palabra(
                    pp.almacenero.nombre +
                      ' ' +
                      pp.almacenero.apellido_paterno +
                      ' ' +
                      pp.almacenero.apellido_materno
                  )}
              </div>

              <div style={e.elemContenidoCampo7(esComputadora)}>
                <div style={e.fecha(esComputadora)}>Fecha Salida</div>
                <div style={e.fecha_formateada(esComputadora)}>
                  {pp.fecha
                    ? fg.formatear_fecha_ddmmyyyy_hora12(pp.fecha, 'AH')
                    : '-----------'}
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  )
}

export default ListaSalidas
