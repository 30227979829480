import { useEffect, useState, useContext } from 'react'
import { Contexto } from '../../../index'
import * as e from './InsumosCSS'
import * as api from '../../../../../Api/Api'
import * as fg from '../../../../../Api/FuncionesGlobales'
import agregar from './../../../../../Imagenes/Iconos/agregar.png'
import editar from './../../../../../Imagenes/Iconos/editar.png'
import borrar from './../../../../../Imagenes/Iconos/borrar.png'
import Boton from '../../../../../Elementos/Boton'
import MAgregarInsumo from './MAgregarInsumo'
import MEliminarInsumo from './MEliminarInsumo'

function Insumos () {
  const { esComputadora } = useContext(Contexto)

  const [listado, setListado] = useState([])
  //eventos
  const [elemSelec, setElemSelec] = useState(null)
  const [elemSelec2, setElemSelec2] = useState(null)
  const [dscElemSelec, setDscElemSelec] = useState(null)
  const [dscElemSelec2, setDscElemSelec2] = useState(null)
  //modales
  const [verModal, setVerModal] = useState(false)
  const [tipoAccion, setTipoAccion] = useState('')
  const [elemclick, setElemClick] = useState('')

  useEffect(() => {
    if (!verModal) {
      const inicio = async () => {
        const listaInsumosCall = await api.listarInsumos(
          null,
          null,
          null,
          null,
          null,
          null,
          null
        )
        console.log(listaInsumosCall.insumo)
        if (listaInsumosCall) {
          if (listaInsumosCall.SMListaErrores.length === 0) {
            setListado(listaInsumosCall.insumo)
          }
        }
      }
      inicio()
    }
  }, [verModal])

  const cerrarModal = e => {
    setVerModal(false)
  }
  return (
    <>
      {verModal && (
        <div>
          {tipoAccion === 'agregarinsumo' ||
          tipoAccion === 'editarinsumo' ||
          tipoAccion === 'editarpreventistainsumo' ||
          tipoAccion === 'relprevinsumo' ? (
            <MAgregarInsumo
              tipoAccion={tipoAccion}
              cerrarModal={cerrarModal}
              esComputadora={esComputadora}
              codigoElem={
                tipoAccion === 'editarpreventistainsumo' || tipoAccion === 'relprevinsumo' ? elemclick : elemSelec
              }
              codigoElem2={elemSelec2}
            />
          ) : (
            <MEliminarInsumo
              dscElemSelec={dscElemSelec}
              tipoAccion={tipoAccion}
              cerrarModal={cerrarModal}
              esComputadora={esComputadora}
              codigoElem={
                tipoAccion === 'eliminarpreventistainsumo'
                  ? elemclick
                  : elemSelec
              }
              codigoElem2={elemSelec2}
            />
          )}
        </div>
      )}
      <div id='contenedor' style={e.contenedor(esComputadora)}>
        <div id='titulo' style={e.titulo(esComputadora)}>
          Insumos
        </div>
        <div id='centro' style={e.centro(esComputadora)}>
          <div id='listado' style={e.listado(esComputadora)}>
            <div id='cabeceraListado' style={e.cabeceraListado(esComputadora)}>
              <div
                style={{
                  borderLeft: '0.1vw solid black',
                  ...e.elemCabListado(esComputadora)
                }}
              >
                Nombre
              </div>
              <div style={e.elemCabListado(esComputadora)}>Stock Actual</div>
              <div style={e.elemCabListado(esComputadora)}>Stock Mínimo</div>
              <div style={e.elemCabListado(esComputadora)}>Unidad Medida</div>
              <div style={e.elemCabListado(esComputadora)}>Activo</div>
              <div style={e.elemCabListado(esComputadora)}>Opciones</div>
            </div>
            {listado.length > 0 &&
              listado.map(emp => (
                <div key={emp.insumo.codigo}>
                  <div
                    id={emp.insumo.codigo}
                    onMouseEnter={() => {
                      setElemSelec(emp.insumo.codigo)
                    }}
                    onMouseLeave={() => {
                      setElemSelec(null)
                    }}
                    onClick={e => {
                      //console.log(elemclick)
                      setElemClick(emp.insumo.codigo)
                      if (elemclick === emp.insumo.codigo) {
                        setElemClick('')
                      }
                    }}
                    style={e.cuerpoListado(esComputadora)}
                  >
                    <div
                      style={{
                        ...e.elemCuerpoListado(esComputadora),
                        ...(emp.insumo.activo === 'N' &&
                          e.inactivoElem(esComputadora)),
                        ...(elemSelec === emp.insumo.codigo &&
                          e.elemCuerpoHoverListado(esComputadora)),
                        ...(elemclick === emp.insumo.codigo &&
                          e.elemCuerpoClickListado(esComputadora))
                        //justifyContent: "left"
                      }}
                    >
                      {emp.insumo.nombre}
                    </div>

                    <div
                      style={{
                        ...e.elemCuerpoListado(esComputadora),
                        ...(emp.insumo.activo === 'N' &&
                          e.inactivoElem(esComputadora)),
                        ...(elemSelec === emp.insumo.codigo &&
                          e.elemCuerpoHoverListado(esComputadora)),
                        ...(elemclick === emp.insumo.codigo &&
                          e.elemCuerpoClickListado(esComputadora))
                      }}
                    >
                      {emp.insumo.stock}
                    </div>
                    <div
                      style={{
                        ...e.elemCuerpoListado(esComputadora),
                        ...(emp.insumo.activo === 'N' &&
                          e.inactivoElem(esComputadora)),
                        ...(elemSelec === emp.insumo.codigo &&
                          e.elemCuerpoHoverListado(esComputadora)),
                        ...(elemclick === emp.insumo.codigo &&
                          e.elemCuerpoClickListado(esComputadora))
                      }}
                    >
                      {emp.insumo.stock_minimo}
                    </div>
                    <div
                      style={{
                        ...e.elemCuerpoListado(esComputadora),
                        ...(emp.insumo.activo === 'N' &&
                          e.inactivoElem(esComputadora)),
                        ...(elemSelec === emp.insumo.codigo &&
                          e.elemCuerpoHoverListado(esComputadora)),
                        ...(elemclick === emp.insumo.codigo &&
                          e.elemCuerpoClickListado(esComputadora))
                      }}
                    >
                      {emp.insumo.unidades + ' ' + emp.insumo.unidad_medida}
                    </div>

                    <div
                      style={{
                        ...e.elemCuerpoListado(esComputadora),
                        ...(emp.insumo.activo === 'N' &&
                          e.inactivoElem(esComputadora)),
                        ...(elemSelec === emp.insumo.codigo &&
                          e.elemCuerpoHoverListado(esComputadora)),
                        ...(elemclick === emp.insumo.codigo &&
                          e.elemCuerpoClickListado(esComputadora))
                      }}
                    >
                      {emp.insumo.activo === 'S' ? 'Si' : 'No'}
                    </div>
                    <div
                      style={{
                        ...e.elemCuerpoOpListado(esComputadora),
                        ...(emp.insumo.activo === 'N' &&
                          e.inactivoElem(esComputadora)),
                        ...(elemSelec === emp.insumo.codigo &&
                          e.elemCuerpoHoverListado(esComputadora)),
                        ...(elemclick === emp.insumo.codigo &&
                          e.elemCuerpoClickListado(esComputadora))
                      }}
                    >
                      <img
                        src={editar}
                        style={e.img(esComputadora)}
                        onClick={e => {
                          setVerModal(true)
                          setTipoAccion('editarinsumo')
                          setElemSelec(e.currentTarget.parentNode.parentNode.id)
                          setElemClick(e.currentTarget.parentNode.parentNode.id)
                          setElemSelec2('')
                        }}
                      />
                      <img
                        src={borrar}
                        style={e.img(esComputadora)}
                        onClick={e => {
                          setDscElemSelec(
                            e.currentTarget.parentNode.parentNode
                              .firstElementChild.innerHTML
                          )
                          setVerModal(true)
                          setTipoAccion('eliminarInsumo')
                          setElemSelec2('')
                        }}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      boxSizing: 'border-box',
                      height: '0',
                      overflow: 'auto',
                      transition: 'all 0s ease-in-out',
                      ...(emp.insumo.codigo === elemclick &&
                        e.detalleInsumo(esComputadora))
                    }}
                  >
                    <div style={{display: "flex",width: esComputadora ? "10vw":"15vw", }}>
                      <Boton
                        style={{
                          backgroundColor: '#ECAB00',
                          borderRadius: esComputadora ? '0vw 0.5vw 0.5vw 0vw' : '0vw 1vw 1vw 0vw',
                          padding: esComputadora ? '0.8vw' : '2vw',
                        }}
                        textoStyle={{
                          color: 'black',
                          width: '100%',
                          fontSize: esComputadora ? '0.9vw' : '2vw',
                          fontWeight: "bolder"
                        }}
                        texto='Relacionar Preventista'
                        tipo='submit'
                        estaProcesando={false}
                        id='relprevinsumo'
                        onClick={e => {
                          setVerModal(true)
                          setTipoAccion(e.currentTarget.id)
                        }}
                      />
                    </div>
                    <table
                      style={{
                        textAlign: 'center',
                        borderSpacing: '4vw 0.5vw',
                        borderCollapse: 'separate'
                      }}
                    >
                      <thead>
                        <tr>
                          <th style={e.detalleElemInsumo(esComputadora)}>
                            Nombre Preventista
                          </th>
                          <th style={e.detalleElemInsumo(esComputadora)}>
                            Empresa
                          </th>
                          <th style={e.detalleElemInsumo(esComputadora)}>
                            Marca
                          </th>
                          <th style={e.detalleElemInsumo(esComputadora)}>
                            Stock Actual
                          </th>
                          <th style={e.detalleElemInsumo(esComputadora)}>
                            Stock Mínimo
                          </th>
                          <th style={e.detalleElemInsumo(esComputadora)}>
                            Opciones
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {emp.preventistas.map(emp_2 => (
                          <tr id={emp_2.codigo} key={emp_2.codigo}>
                            <td>
                              {emp_2.preventistas !== null
                                ? emp_2.preventistas.nombre +
                                  ' ' +
                                  emp_2.preventistas.apellido_paterno +
                                  ' ' +
                                  emp_2.preventistas.apellido_materno
                                : ''}
                            </td>
                            <td>
                              {emp_2.empresa !== null
                                ? emp_2.empresa.nombre
                                : '-'}
                            </td>
                            <td>
                              {emp_2.marca !== null ? emp_2.marca.nombre : '-'}
                            </td>
                            <td>{emp_2.stock !== null ? emp_2.stock : '-'}</td>
                            <td>
                              {emp_2.stock_minimo !== null
                                ? emp_2.stock_minimo
                                : '-'}
                            </td>
                            <td
                              style={{
                                //...e.elemCuerpoOpListado(esComputadora),
                                backgroundColor: '#fff6de',
                                width: '20%'
                              }}
                            >
                              <img
                                src={editar}
                                style={{
                                  ...e.img(esComputadora),
                                  width: '12%',
                                  height: 'auto',
                                  marginRight: '1vw'
                                }}
                                onClick={e => {
                                  setVerModal(true)
                                  setTipoAccion('editarpreventistainsumo')
                                  setElemSelec2(
                                    parseInt(
                                      e.currentTarget.parentNode.parentNode.id
                                    )
                                  )
                                  //setElemClick(e.currentTarget.parentNode.parentNode.id)
                                }}
                              />
                              <img
                                src={borrar}
                                style={{
                                  ...e.img(esComputadora),
                                  width: '12%',
                                  height: 'auto'
                                }}
                                onClick={e => {
                                  setDscElemSelec(
                                    e.currentTarget.parentNode.parentNode
                                      .firstElementChild.innerHTML
                                  )
                                  setVerModal(true)
                                  setTipoAccion('eliminarpreventistainsumo')
                                  setElemSelec2(
                                    parseInt(
                                      e.currentTarget.parentNode.parentNode.id
                                    )
                                  )
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div style={e.opciones(esComputadora)}>
          <div>
            <Boton
              style={{
                backgroundColor: '#2D8B0B',
                borderRadius: esComputadora ? '0.5vw' : '1vw',
                padding: esComputadora ? '0.8vw' : '2vw'
              }}
              textoStyle={{
                color: 'white',
                width: '100%',
                fontSize: esComputadora ? '1vw' : '2.5vw'
              }}
              texto='Agregar Insumo'
              tipo='submit'
              estaProcesando={false}
              id='agregarinsumo'
              onClick={e => {
                setVerModal(true)
                setTipoAccion(e.currentTarget.id)
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Insumos
