import React, { useState, useEffect, useContext } from 'react'
import { Contexto } from '../../../../../Componentes/Principal/index'
import * as estilos from './GenerarPedidoInsumosCSS'
import Input from '../../../../../Elementos/Input'
import Opciones from '../../../../../Elementos/Opciones'
import Boton from '../../../../../Elementos/Boton'
import * as api from '../../../../../Api/Api'

function GenerarPedidoInsumos (param) {
  const { esComputadora, rol } = useContext(Contexto)
  const [empop, setempop] = useState(null)
  const [prevop, setprevop] = useState(null)

  const [previn, setprevin] = useState('')
  const [empin, setempin] = useState('')
  const [pedidoinfo, setpedidoinfo] = useState(null)

  const [listPedIns, setListPedIns] = useState([])
  const [pedlist, setpedlist] = useState([])
  const [comenpedin, setcomenpedin] = useState('')

  useEffect(() => {
    const inicio = async () => {
      const empcall = await api.listarEmpresas(null, 'S', 'N')
      const prevcall = await api.listarPreventistas(0, 'S')
      if (empcall && prevcall) {
        if (
          empcall.SMListaErrores.length === 0 &&
          prevcall.SMListaErrores.length === 0
        ) {
          setempop(empcall.empresa)
          setprevop(prevcall.preventistas)
        }
      }
    }
    inicio()
  }, [])
  const salgodeinput = async e => {
    let codmarca = parseInt(e.currentTarget.parentNode.parentNode.parentNode.id)
    let codinsumo = parseInt(
      e.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode.id
    )

    let valor =
      e.currentTarget.value === '' ? 0 : parseFloat(e.currentTarget.value)

    const editarInput = await api.editarPedidoInsumoDetalle(
      pedidoinfo.pedido,
      valor,
      1,
      codinsumo,
      codmarca
    )
    if (editarInput) {
      if (editarInput.SMListaErrores.length === 0) {
      } else {
        console.log(editarInput)
      }
    }
  }
  useEffect(() => {
    console.log(empin, previn)
    const actualizar = async () => {
      if (empin !== '') {
        const prevdom = document.getElementById('previn')
        if (prevdom) prevdom.value = ''
        setprevin('')
        const listapedidosact = await api.pedidoInsumoDetalle(
          null,
          parseInt(empin),
          '0'
        )
        if (listapedidosact) {
          if (listapedidosact.SMListaErrores.length === 0) {
            setpedidoinfo(listapedidosact.pedido)
            setListPedIns(listapedidosact.insumosPedidoJson)
          }
        }
      }
      if (previn !== '') {
        const empdom = document.getElementById('empin')
        if (empdom) empdom.value = ''
        setempin('')
        const listapedidosact = await api.pedidoInsumoDetalle(null, 0, previn)
        
        if (listapedidosact) {
          if (listapedidosact.SMListaErrores.length === 0) {
            setpedidoinfo(listapedidosact.pedido)
            setListPedIns(listapedidosact.insumosPedidoJson)
          }
        }
      }
      if (empin === '' && previn === '') {
        const prevdom = document.getElementById('previn')
        if (prevdom) prevdom.value = ''
        const empdom = document.getElementById('empin')
        if (empdom) empdom.value = ''
        setListPedIns([])
      }
    }
    actualizar()
  }, [empin, previn])

  return (
    <>
      <div style={estilos.cabeceraVista(esComputadora)}>
        {'Generar Pedido (Insumos)'}
      </div>
      <div style={estilos.cabeceraFiltros(esComputadora)}>
        <div style={estilos.campof1(esComputadora)}>
          <Opciones
            style={
              {
                /*...(envioForm &&
              estilos.estiloVacio(esComputadora, ''))*/
              }
            }
            id='empin'
            moderno={'Empresas'}
            opciones={empop !== null ? empop : []}
            clave='codigo'
            valor={['nombre']}
            esComputadora={esComputadora}
            onClick={e => {
              setempin(e)
              setprevin('')
            }}
            programaOrigen='agregarEditarProductos'
            valorInicial={empin !== '' ? true : false}
          />
        </div>
        <div style={estilos.campof2(esComputadora)}>
          <Opciones
            style={
              {
                /*...(envioForm &&
              estilos.estiloVacio(esComputadora, ''))*/
              }
            }
            id='previn'
            moderno={'Preventistas'}
            opciones={prevop !== null ? prevop : []}
            clave='usuario'
            valor={['nombre', 'apellido_paterno', 'apellido_materno']}
            esComputadora={esComputadora}
            onClick={e => {
              setprevin(e)
              setempin('')
            }}
            programaOrigen='agregarEditarProductos'
            valorInicial={previn !== '' ? true : false}
          />
        </div>
      </div>
      <div style={estilos.cuerpo(esComputadora)}>
        <div>
          <Input
            style={{
              //fontWeight: 'bolder',
              fontSize: esComputadora ? '1.2vw' : '2.5vw'
            }}
            textoStyle={{
              fontWeight: 'bolder',
              fontSize: esComputadora ? '1.2vw' : '2.5vw'
            }}
            id='fechaPedido'
            moderno='Comentario de Pedido'
            tipo='text'
            onChange={() => {}}
            valorInicial={comenpedin}
            maxDigitos='50'
            esComputadora={esComputadora}
          />
        </div>
        <div id='contitem' style={estilos.contenidoItems(esComputadora)}>
          {listPedIns.length > 0 &&
            listPedIns.map(lp => (
              <div
                id={lp.insumo.codigo}
                key={lp.insumo.codigo}
                style={estilos.cuerpoitem(esComputadora)}
              >
                <div style={estilos.cuerpoitemcab(esComputadora)}>
                  {lp.insumo.nombre +
                    ' (' +
                    lp.insumo.unidades +
                    ' ' +
                    lp.insumo.unidad_medida +
                    ')'}
                </div>
                <div style={estilos.cuerpoitemcue(esComputadora)}>
                  <div
                    style={{
                      ...estilos.cuerpoitemcuecab(esComputadora),
                      fontWeight: 'bolder'
                      //height: '20%'
                    }}
                  >
                    <div style={estilos.campo0(esComputadora)}>
                      Marca Nombre
                    </div>
                    <div style={estilos.campo2(esComputadora)}>
                      Cant. Solicitada
                    </div>
                  </div>
                  {lp.detallePedido.length > 0 &&
                    lp.detallePedido.map(dp => (
                      <div
                        id={dp.marca.codigo}
                        key={dp.marca.codigo}
                        style={{
                          ...estilos.cuerpoitemcuecue(esComputadora)
                          //height: '80%'
                        }}
                      >
                        <div style={estilos.campo0(esComputadora)}>
                          {dp.marca.nombre}
                        </div>

                        <div style={estilos.campo2(esComputadora)}>
                          <Input
                            style={{
                              fontWeight: 'bolder',
                              fontSize: esComputadora ? '1.2vw' : '2.5vw',
                              padding: esComputadora ? '0.2vw' : '0.7vw',
                              width: '100%'
                            }}
                            textoStyle={{
                              fontWeight: 'bolder',
                              fontSize: esComputadora ? '1vw' : '2.5vw'
                            }}
                            id='inputin'
                            moderno=''
                            tipo='number'
                            maxDigitos='10'
                            autoComplete
                            onChange={e => {
                              if (e.currentTarget.value === '') {
                                salgodeinput(e)
                              }
                            }}
                            onBlur={salgodeinput}
                            valorInicial={dp.solicitado}
                            esComputadora={esComputadora}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))}
        </div>
      </div>
      <div style={estilos.opcionesBotones(esComputadora)}>
        <div>
          <Boton
            style={{
              backgroundColor: '#2D8B0B',
              borderRadius: esComputadora ? '0.5vw' : '1vw',
              padding: esComputadora ? '0.8vw' : '2vw'
            }}
            textoStyle={{
              color: 'white',
              width: '100%',
              fontSize: esComputadora ? '1vw' : '2.5vw'
            }}
            texto={
              rol === 'SUPER ADMINISTRADOR'
                ? 'Aprobar Pedido'
                : 'Finalizar Ingreso'
            }
            tipo='submit'
            tonalidad='20'
            estaProcesando={false}
            onClick={async () => {
              const fining = await api.guardarPedidoInsumo(
                pedidoinfo.pedido,
                comenpedin,
                1
              )
              if (fining) {
                if (fining.SMListaErrores.length === 0) {
                  setprevin('')
                  setempin('')
                } else {
                  console.log(fining)
                }
              }
            }}
          />
        </div>
      </div>
    </>
  )
}

export default GenerarPedidoInsumos
