export const cabeceraVista = esComputadora => ({
  gridArea: '1/1/2/21',
  backgroundColor: '#AD6726',
  fontWeight: 'bolder',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  borderRadius: esComputadora ? '1vw 1vw 0vw 0vw' : '1vw 1vw 0vw 0vw',
  fontSize: esComputadora ? '2vw' : '4vw',
})
export const cabeceraFiltros = esComputadora => ({
  gridArea: '2/1/4/21',
  //backgroundColor: esComputadora ? 'green' : 'green',
  display: 'grid',
  gridTemplateColumns: 'repeat(20,1fr)',
  gridTemplateRows: 'repeat(4,1fr)',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: "0.5gvw"
})
const campof = () => ({
  padding: '0.1vw'
})

export const campof1 = esComputadora => ({
  gridArea: '1/1/5/11',
  fontSize: esComputadora ? '1vw' : '2vw',
  ...campof()
})
export const campof2 = esComputadora => ({
  gridArea: '1/11/5/21',
  fontSize: esComputadora ? '1vw' : '2vw',
  ...campof()
})
export const cuerpo = esComputadora => ({
  gridArea: '4/1/21/21',
  //backgroundColor: esComputadora ? 'skyblue' : 'skyblue',
  display: esComputadora ? 'flex':'',
  flexWrap: "wrap",
  justifyContent: "center",
  gap: '0.5vw 0.5vw',
  //flexDirection: 'row',
  //flexWrap: 'wrap',
  //gap: esComputadora ? '1vw 1vw' : '0.5vw 0.5vw',
  //justifyContent: 'center',
  //alignItems: 'center',
  overflow: 'auto',
})
export const elemContenido = (esComputadora) => ({
  //display: "inline-block",
  backgroundColor: '#FFF7A9',
  width: esComputadora ? '27vw' : '87vw',
  height: esComputadora ? '22vh' : '14vh',
  marginBottom: esComputadora ? '' : '1vw',
  marginRight: "1vw",
  display: 'grid',
  gridTemplateColumns: 'repeat(20,1fr)',
  gridTemplateRows: 'repeat(20,1fr)',
  boxSizing: "border-box",
  padding: "1vw",
  border: "0.1vw solid #CCCCCC",
  borderRadius: esComputadora ? '1vw':'2vw',
  boxShadow: esComputadora ? '0.2vw 0.2vw 0.5vw #cccccc':'0.5vw 0.5vw 1vw #cccccc',
  cursor: "pointer",
  marginTop: "1vw"
})
export const elemContenidoCampo = (esComputadora) => ({
    fontSize: esComputadora ? '1vw':'2vw',
    //backgroundColor: "green",
    //padding: '0.5vw',
    boxSizing: 'border-box',
    
})
export const elemContenidoCampo1 = esComputadora => ({
  color:"#363636",
  gridArea: '1/2/4/9',
  ...elemContenidoCampo(esComputadora),
  fontWeight: 'bolder',
  fontSize: esComputadora ? '1.2vw':'3vw'
})
export const elemContenidoCampo2 = esComputadora => ({
  color:"#363636",
  gridArea: '1/12/4/20',
  ...elemContenidoCampo(esComputadora),
  fontWeight: 'bolder',
  fontSize: esComputadora ? '1.2vw':'2.5vw',
  textAlign: "right"
})
export const elemContenidoCampo3 = esComputadora => ({
  color:"#737373",
    gridArea: '4/1/7/21',
    ...elemContenidoCampo(esComputadora)
})
export const elemContenidoCampo4 = esComputadora => ({
  color:"#737373",
    gridArea: '7/1/10/21',
    ...elemContenidoCampo(esComputadora)
})
export const elemContenidoCampo5 = esComputadora => ({
  color:"#737373",
    gridArea: '10/1/13/21',
    ...elemContenidoCampo(esComputadora)
})
export const elemContenidoCampo6 = esComputadora => ({
  color:"#737373",
    gridArea: '13/1/16/7',
    ...elemContenidoCampo(esComputadora)
})
export const elemContenidoCampo7 = esComputadora => ({
  color:"#737373",
    gridArea: '13/7/16/14',
    ...elemContenidoCampo(esComputadora)
})
export const elemContenidoCampo8 = esComputadora => ({
  color:"#737373",
    gridArea: '13/14/16/21',
    ...elemContenidoCampo(esComputadora)
})
export const elemContenidoCampo9 = esComputadora => ({
  color:"#737373",
    gridArea: '13/14/16/21',
    ...elemContenidoCampo(esComputadora)
})
export const encabezados = (esComputadora) => ({
  color:"#868686",
  fontWeight: "bolder"
})
export const fecha = (esComputadora) => ({
  fontWeight: "bolder",
  color:"#868686",
  textAlign: "center"
})
export const fecha_formateada = (esComputadora) => ({
    textAlign: "center",
    color:"#737373"
})
