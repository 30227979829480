import React, { useState, useEffect } from 'react'

import * as e from './OpcionesCSS'
import * as fg from './../Api/FuncionesGlobales'

function Opciones (param) {
  
  const [isContent, setIsContent] = useState(false)

  useEffect(() => {
    const ejecucion = async () => {
      let inicial = param.valorInicial ? true : false
      if (inicial) {
        setIsContent(true)
      }else
        setIsContent(false)
    }
    ejecucion()
  }, [param.valorInicial])

  useEffect(() => {
    if (param.limpiar){
      setIsContent(false)
    }
  }, [param.limpiar])
  return (
    <>
      <div id='lista' style={e.contenedorLista(param.esComputadora)}>
        <select
          disabled={param.deshabilitado}
          id={param.id ? param.id : 'opcionesGenerico'}
          style={{
            ...e.listastyle(param.esComputadora, param.style,param.deshabilitado)
            ,...param.textoStyle
          }}
          onChange={async event => {
            
            if (event.currentTarget.value) setIsContent(true)
            else setIsContent(false)
            await param.onClick(event.target.value)
          }}
          onBlur={evento => {
            if (evento.currentTarget.value === '') setIsContent(false)
          }}
        >
          <option value='' style={e.opcionStyle(param.esComputadora)}>
            {' '}
          </option>
          {param.opciones.map(i => {
            return (
              <option
                key={i[param.clave]}
                value={i[param.clave]}
                style={{...e.opcionStyle(param.esComputadora),...param.textoStyle}}
              >
                {param.valor.map(val => fg.capitalizar_palabra(i[val]) + ' ')}
              </option>
            )
          })}
        </select>

        <label
          id='textosinvalor'
          style={{
            ...e.inputLabelCSS(param.esComputadora, param.textoStyle),
            ...(isContent ? e.inputLabelActivoCSS(param.esComputadora) : {})
          }}
        >
          {param.moderno}
        </label>
      </div>
    </>
  )
}

export default Opciones
