import { hexToRGBA } from '../../../../../Api/Api'

export const baseModal = esComputadora => ({
  position: 'absolute',
  left: '0',
  top: '0',
  width: '100vw',
  height: '100vh',
  backgroundColor: hexToRGBA('#C1C1C1', 0.5),
  zIndex: 40
})
export const botonCerrarModalStyle = esComputadora => ({
  display: 'flex',
  gridArea: esComputadora ? ' 1 / 18 / 2 / 21' : ' 1 / 18 / 2 / 21',
  justifySelf: 'right',
  alignItems: 'center'
})

const widthModal = esComputadora => {
  return esComputadora ? '90' : '90'
}
const heightModal = esComputadora => {
  return esComputadora ? '85' : '90'
}
export const contenedorModal = esComputadora => ({
  borderRadius: '10px 10px 0px 0px',
  display: 'grid',
  gridTemplateColumns: 'repeat(4,1fr)',
  gridTemplateRows: 'repeat(2,1fr)',
  position: 'absolute',
  left: (100 - widthModal(esComputadora)) / 2 + 'vw',
  top: (100 - heightModal(esComputadora)) / 2 - 2.5 + 'vh',
  width: widthModal(esComputadora) + 'vw',
  backgroundColor: hexToRGBA('#ffffff', 1),
  zIndex: '41',
  boxSizing: 'border-box'
})
export const tituloCSS = esComputadora => ({
  gridArea: esComputadora ? ' 1 / 2 / 1 / 4' : ' 1 / 1 / 1 / 5',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  //backgroundColor: "green",
  fontSize: esComputadora ? '1.5vw' : '3.5vw'
})
export const contenidoModal = esComputadora => ({
  gridArea: '1/1/21/21',
  position: 'absolute',
  top: '5vh',
  width: widthModal(esComputadora) + 'vw',
  height: heightModal(esComputadora) + 'vh',
  backgroundColor: hexToRGBA('#ffffff', 1),
  zIndex: '42',
  borderRadius: esComputadora ? '0px 0px 0.5vw 0.5vw' : '0px 0px 1vw 1vw',
  padding: esComputadora ? '2vw':'4vw',
  boxSizing: 'border-box',
})
export const formularioCSS = esComputadora => ({
  width: '100%',
  height: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(20,1fr)',
  gridTemplateRows: '2.5% repeat(19,1fr)',
  //marginTop: esComputadora ? "1vw":"2vw",
  boxSizing: 'border-box',
})
export const codigocab = (esComputadora) => ({
  gridArea: '1/1/2/21',
  fontSize: esComputadora ? '1.2vw':'3vw',
  textAlign: "right",
  fontWeight: "bolder"
})
export const cabecera = (esComputadora) => ({
  gridArea: '2/1/3/21',
  display: "grid",
  gridTemplateColumns: 'repeat(21,1fr)',
  gridTemplateRows: 'repeat(1,1fr)',
  padding: "0.5vw"
})
export const fecha = (esComputadora) =>({
  padding: '0.5vw',
})
export const fechaSol = (esComputadora)=>({
  gridArea: '1/1/2/8',
  ...(fecha(esComputadora))
})
export const fechaAprob = (esComputadora)=>({
  gridArea: '1/8/2/15',
  ...(fecha(esComputadora))
})
export const fechaIng = (esComputadora)=>({
  gridArea: '1/15/2/22',
  ...(fecha(esComputadora))
})
export const cuerpo = (esComputadora) =>({
  gridArea: '3/1/19/21',
  backgroundColor: "#eeeeee",
  boxShadow: "0.1vw 0.1vw 1vw #aaaaaa",
  padding: "1vw",
  overflow: "auto",
  
})
export const contenidoItems = (esComputadora)=>({
  display: esComputadora ? 'flex':'',
  //flexDirection: "row",
  //backgroundColor: "green",
  width: "100%",
  flexWrap : 'wrap',
  justifyContent: "center",
  gap: "0px 0.5vw"
})
export const cuerpoitem = (esComputadora) => ({
  marginTop: '1vw',
  width: esComputadora ? '26vw' : '100%',
  height: esComputadora ? '15vh' : 'auto',
  //backgroundColor: 'blue',
  border: "1px solid #cccccc",
  borderRadius: '1vw',
  //flex: '1 0 calc(33.333% - 0.5vw)'
})
export const cuerpoitemcab = (esComputadora) => ({
  backgroundColor: '#AD6726',
  borderRadius: '1vw 1vw 0 0',
  textAlign: "center",
  color: "white",
  fontWeight: "bolder",
  height: "20%",
  fontSize: esComputadora ? '1.2vw' : '2.5vw'
})
export const cuerpoitemcue = (esComputadora) => ({
  backgroundColor: 'white',
  borderRadius: '0 0 1vw 1vw',
  height: "80%",
  width: "100%",
  overflow: "auto"
})
export const cuerpoitemcuecab = (esComputadora) => ({
  display: "grid",
  gridTemplateColumns: "repeat(6,1fr)",
  gridTemplateRows: "repeat(1,1fr)",
  //backgroundColor: "green",
})
export const cuerpoitemcuecue = (esComputadora) => ({
  display: "grid",
  gridTemplateColumns: "repeat(6,1fr)",
  gridTemplateRows: "repeat(1,1fr)",
  //backgroundColor: "blue",
})
export const campo0=(esComputadora)=>({
  gridArea: "1/1/2/3",
  padding: "0.5vw",
  fontSize: esComputadora ? '1vw':'2.5vw',
  color: "#555555",
  textAlign: "center"
})
export const campo1=(esComputadora)=>({
  gridArea: "1/3/2/5",
  padding: "0.5vw",
  fontSize: esComputadora ? '1vw':'2.5vw',
  color: "#555555",
  textAlign: "center"
})
export const campo2=(esComputadora)=>({
  gridArea: "1/5/2/7",
  padding: "0.5vw",
  fontSize: esComputadora ? '1vw':'2.5vw',
  color: "#555555",
  textAlign: "center"
})
export const campo3=(esComputadora)=>({
  gridArea: "3/1/4/11",
  padding: "0.5vw"
})
export const campo4=(esComputadora)=>({
  gridArea: "3/11/4/21",
  padding: "0.5vw"
})
export const campo5=(esComputadora)=>({
  gridArea: "4/1/5/11",
  padding: "0.5vw"
})
export const campo6=(esComputadora)=>({
  gridArea: "4/11/5/21",
  padding: "0.5vw",
  fontSize: esComputadora ? '1vw':'2vw',
  color: "#999999"
})
export const campo6_7=(esComputadora)=>({
  gridArea: "5/1/5/21",
  padding: "0.5vw",
  fontSize: esComputadora ? '1vw':'2.5vw',
  color: "#555555",
  textAlign: "center"
})
export const campo7=(esComputadora)=>({
  gridArea: "6/1/7/11",
  padding: "0.5vw"
})
export const campo8=(esComputadora)=>({
  gridArea: "6/11/7/21",
  padding: "0.5vw"
})
export const campo9=(esComputadora)=>({
  gridArea: "7/1/8/11",
  padding: "0.5vw"
})
export const campo10=(esComputadora)=>({
  gridArea: "7/11/8/21",
  padding: "0.5vw",
  fontSize: esComputadora ? '1vw':'2vw',
  color: "#999999"
})
export const radioButton = (esComputadora)=>({
  width: "10px"
})
export const propietario=(esComputadora)=>({
  gridArea: "2/11/2/21",
  padding: "0.5vw",
})

export const activo=(esComputadora)=>({
  gridArea: "2/11/2/21",//"2/1/2/11",
  fontSize: esComputadora ? "1vw":"2vw",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexDirection: "column",
  padding: "0.5vw",
})
export const confirmar = (esComputadora)=>({
  gridArea : "20/1/21/11",
  padding: "0.5vw"
})
export const cancelar = (esComputadora)=>({
  gridArea : "20/11/21/21",
  padding: "0.5vw"
})
export const opcionesBotones = (esComputadora) =>({
  gridArea: '19/1/21/21',
  backgroundColor: "#ad672633",
  boxShadow: "0.1vw 0.1vw 1vw #aaaaaa",
  padding: "1vw",
  overflow: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
})
export const estiloVacio = (esComputadora, vacio) => ({
  border:
    vacio === '' || vacio === 0
      ? `${esComputadora ? '0.1vw' : '0.2vw'} solid red`
      : `${esComputadora ? '0.1vw' : '0.2vw'} solid #ccc`
})

