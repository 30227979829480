import React, { useState, useEffect, useRef, useCallback } from 'react'
import * as estilos from './MAgregarInsumoCSS'
import Boton from '../../../../../Elementos/Boton'
import Input from '../../../../../Elementos/Input'
import Opciones from '../../../../../Elementos/Opciones'
import * as api from '../../../../../Api/Api'
import * as fg from '../../../../../Api/FuncionesGlobales'

function MAgregarInsumo (param) {
  const [estaProcesando, setestaProcesando] = useState(false)
  const [estadoopcion, setestadoopcion] = useState(
    (function () {
      if (param.tipo === 'editar') {
        if (Object.keys(param.param['productos']).length !== 1) {
          return 'NULL'
        }
      }
    })()
  )
  const [estadoopcionprev, setestadoopcionprev] = useState(
    (function () {
      if (param.tipo === 'editar') {
        if (Object.keys(param.param['productos']).length !== 1) {
          return 'NULL'
        }
      }
    })()
  )

  const [unimedop, setunimedop] = useState([
    { codigo: 'Kg.', dsc: 'Kilogramo (Kg)' },
    { codigo: 'Lt.', dsc: 'Litro (Lt)' },
    { codigo: 'Oz.', dsc: 'Onzas (Oz)' },
    { codigo: 'Und.', dsc: 'Unidades (Und)' }
  ])
  const [datosprevin, setdatosprevin] = useState(null)

  const [empop, setempop] = useState(null)
  const [marcaop, setmarcaop] = useState(null)
  const [marcaslist, setmarcaslist] = useState(null)
  const [prevop, setprevop] = useState(null)
  const [prevlist, setprevlist] = useState(null)

  const [nombreIn, setNombreIn] = useState('')
  const [stockminimoin, setstockminimoin] = useState('')
  const [medidain, setmedidain] = useState('')
  const [unidadmedidain, setunidadmedidain] = useState('')
  const [empin, setempin] = useState('')
  const [marcain, setmarcain] = useState('')
  const [previn, setprevin] = useState('')
  const [habilitadoIn, setHabilitadoIn] = useState('')
  const [habilitadoprevin, sethabilitadoprevin] = useState('')

  const [envioForm, setEnvioForm] = useState(false)
  const [codigoElem, setCodigoElem] = useState(null)

  const [camempori, setcamempori] = useState(false)

  useEffect(() => {
    console.log(param)
    const inicio = async () => {
      const marcasCall = await api.listarMarcas(null, null, 'S')
      const prevCall = await api.listarPreventistas(null, 'S')
      const empCall = await api.listarEmpresas(null, 'S', 'N')

      if (marcasCall && empCall && prevCall) {
        if (empCall.SMListaErrores.length === 0) {
          setmarcaslist(marcasCall.marca)
          setempop(empCall.empresa)
          setprevlist(prevCall.preventistas)
          setprevop(
            prevCall.preventistas.filter(
              pp => parseInt(pp.empresa.codigo) === parseInt(0)
            )
          )
        }
      }

      if (param.codigoElem !== null) {
        setCodigoElem(param.codigoElem)
        const datosInsumo = await api.listarInsumos(
          param.codigoElem,
          'S',
          null,
          null,
          null,
          null,
          'S'
        )
        console.log(datosInsumo)
        if (datosInsumo) {
          if (datosInsumo.SMListaErrores.length === 0) {
            setNombreIn(datosInsumo.insumo.nombre)
            setmedidain(datosInsumo.insumo.unidades)

            let unidadmedida = document.getElementById('unidadmedidain')
            if (unidadmedida) {
              unidadmedida.value = datosInsumo.insumo.unidad_medida
              setunidadmedidain(datosInsumo.insumo.unidad_medida)
            }
            //obtener id de unidad medida
            if (param.tipoAccion !== 'relprevinsumo')
              setstockminimoin(datosInsumo.insumo.stock_minimo)
            setestadoopcion(datosInsumo.insumo.activo)
            setHabilitadoIn(datosInsumo.insumo.activo)

            if (param.tipoAccion === 'editarpreventistainsumo') {
              let datosprevin = datosInsumo.insumo.preventistas.filter(
                pp => parseInt(pp.codigo) === parseInt(param.codigoElem2)
              )
              if (datosprevin.length > 0) {
                datosprevin = datosprevin[0]
              }
              setdatosprevin(datosprevin)
              const empresaop = document.getElementById('empin')
              if (empresaop) {
                empresaop.value = datosprevin.empresa.codigo
                setempin(datosprevin.empresa.codigo)
              }

              setestadoopcionprev(datosprevin.activo)
              sethabilitadoprevin(datosprevin.activo)
            }
          }
        }
      }
    }
    inicio()
  }, [])

  /*
  useEffect(() => {
    console.log('entre a empin')
    if (marcaslist) {
      console.log(empin)
      console.log(previn)
      console.log(marcain)
      console.log(marcaslist)
      let marcasFiltradas = marcaslist.filter(
        mp => parseInt(mp.empresa.codigo) === parseInt(empin)
      )
      setmarcaop(marcasFiltradas)
    }
    if (camempori) {
      console.log('entre')
      if (marcaop && prevop) {
        const marcaop = document.getElementById('marcain')
        console.log(marcaop)
        if (marcaop) {
          console.log('entre a marcas opciones')
          marcaop.value = 0
          setmarcain('')
        }

        const prevop = document.getElementById('previn')
        if (prevop) {
          console.log('entre a preventistas opciones')
          prevop.value = '0'
          setprevin('')
        }
      }
    }
  }, [empin])
*/
  useEffect(() => {
    
    console.log(empin, previn)
    const actualizar = async () => {
      if (empin !== '') {
        let marcasFiltradas = marcaslist.filter(
          mp => parseInt(mp.empresa.codigo) === parseInt(empin)
        )
        setmarcaop(marcasFiltradas)
        const prevdom = document.getElementById('previn')
        if (prevdom) prevdom.value = ''
        setprevin('')
        
      }
      if (previn !== '') {
        const empdom = document.getElementById('empin')
        if (empdom) empdom.value = ''
        setempin('')
        const marcadom = document.getElementById('marcain')
        if (marcadom) marcadom.value = ''
        setmarcain('')
      }
      if (empin === '' && previn === '') {
      }
    }
    actualizar()
  }, [empin, previn])

  useEffect(() => {
    if (!camempori) {
      if (marcaop && prevop) {
        const marcaop = document.getElementById('marcain')
        console.log(datosprevin)
        if (marcaop && datosprevin) {
          marcaop.value =
            parseInt(datosprevin.marca.codigo) === 0
              ? ''
              : datosprevin.marca.codigo
          setmarcain(
            parseInt(datosprevin.marca.codigo) === 0
              ? ''
              : datosprevin.marca.codigo
          )
        }
        const prevop = document.getElementById('previn')
        if (prevop && datosprevin) {
          prevop.value =
            datosprevin.preventistas.usuario === '0'
              ? ''
              : datosprevin.preventistas.usuario
          setprevin(
            datosprevin.preventistas.usuario === '0'
              ? ''
              : datosprevin.preventistas.usuario
          )
        }
      }
    }
  }, [prevop, marcaop])

  const cambiarestadoopcion = event => {
    setestadoopcion(event.target.value)
    setHabilitadoIn(event.target.value)
  }
  const cambiarestadoopcionPrev = event => {
    setestadoopcionprev(event.target.value)
    sethabilitadoprevin(event.target.value)
  }
  return (
    <>
      <div id='baseModal' style={estilos.baseModal(param.esComputadora)}>
        <div
          id='contenedorModal'
          style={estilos.contenedorModal(param.esComputadora)}
        >
          <div style={estilos.botonCerrarModalStyle(param.esComputadora)}>
            <Boton
              style={{
                backgroundColor: '#E12121',
                borderRadius: param.esComputadora ? '0.5vw' : '1vw',
                padding: param.esComputadora ? '0.8vw' : '2vw'
              }}
              textoStyle={{
                color: 'white',
                width: '100%',
                fontSize: param.esComputadora ? '1vw' : '2.5vw'
              }}
              texto='X'
              tipo='submit'
              tonalidad='20'
              estaProcesando={estaProcesando}
              onClick={() => {
                param.cerrarModal()
              }}
            />
          </div>
          <div style={estilos.tituloCSS(param.esComputadora)}>
            {param.tipoAccion === 'agregarinsumo'
              ? 'Agregar Insumo'
              : param.tipoAccion === 'relprevinsumo'
              ? 'Relacionar Preventista'
              : param.tipoAccion === 'editarpreventistainsumo'
              ? 'Editar Preventista (Insumo)'
              : 'Editar Insumo'}
          </div>
          <div
            id='contenidoModal'
            style={estilos.contenidoModal(param.esComputadora)}
          >
            <div style={estilos.formularioCSS(param.esComputadora)}>
              <div style={estilos.campo0(param.esComputadora)}>
                Datos del Insumo
              </div>
              <div style={estilos.campo1(param.esComputadora)}>
                <Input
                  style={{
                    ...(envioForm &&
                      estilos.estiloVacio(param.esComputadora, nombreIn))
                  }}
                  textoStyle={{}}
                  id='nombre'
                  moderno={'Nombre'}
                  tipo='text'
                  onChange={e => {
                    setNombreIn(e.currentTarget.value)
                  }}
                  valorInicial={nombreIn !== '' ? nombreIn : ''}
                  esComputadora={param.esComputadora}
                  deshabilitado={
                    param.tipoAccion === 'editarpreventistainsumo' ||
                    param.tipoAccion === 'relprevinsumo'
                      ? true
                      : false
                  }
                />
              </div>
              <div style={estilos.campo3(param.esComputadora)}>
                <Input
                  style={{
                    ...(envioForm &&
                      estilos.estiloVacio(param.esComputadora, medidain))
                  }}
                  textoStyle={{}}
                  id='medidain'
                  moderno={'Medida'}
                  tipo='number'
                  maxDigitos='4'
                  onChange={e => {
                    setmedidain(e.currentTarget.value)
                  }}
                  valorInicial={medidain !== '' ? medidain : ''}
                  esComputadora={param.esComputadora}
                  deshabilitado={
                    param.tipoAccion === 'editarpreventistainsumo' ||
                    param.tipoAccion === 'relprevinsumo'
                      ? true
                      : false
                  }
                />
              </div>
              <div style={estilos.campo5(param.esComputadora)}>
                <Input
                  style={{
                    ...(envioForm &&
                      estilos.estiloVacio(param.esComputadora, stockminimoin))
                  }}
                  textoStyle={{}}
                  id='stockminimoin'
                  moderno={'Stock Mínimo'}
                  tipo='number'
                  maxDigitos='4'
                  onChange={e => {
                    setstockminimoin(e.currentTarget.value)
                  }}
                  valorInicial={stockminimoin !== '' ? stockminimoin : ''}
                  esComputadora={param.esComputadora}
                />
              </div>
              <div style={estilos.campo4(param.esComputadora)}>
                <Opciones
                  style={{
                    ...(envioForm &&
                      estilos.estiloVacio(param.esComputadora, unidadmedidain))
                  }}
                  id='unidadmedidain'
                  alto='55px'
                  moderno={'Unidad Medida'}
                  opciones={unimedop !== null ? unimedop : []}
                  clave='codigo'
                  valor={['dsc']}
                  esComputadora={param.esComputadora}
                  onClick={e => {
                    setunidadmedidain(e)
                  }}
                  programaOrigen='agregarEditarProductos'
                  valorInicial={unidadmedidain !== '' ? true : false}
                  deshabilitado={
                    param.tipoAccion === 'editarpreventistainsumo' ||
                    param.tipoAccion === 'relprevinsumo'
                      ? true
                      : false
                  }
                />
              </div>
              <div style={estilos.campo10(param.esComputadora)}>
                <div
                  style={{
                    width: '100%',
                    backgroundColor: 'white',
                    border: '0.1vw solid #cccccc',
                    borderRadius: param.esComputadora ? '0.3vw' : '1vw',
                    ...(envioForm &&
                      estilos.estiloVacio(param.esComputadora, habilitadoIn)),
                    ...(param.tipoAccion === 'editarinsumo' && {
                      backgroundColor: '#cccccc',
                      color: '#555555'
                    })
                  }}
                >
                  <div>
                    <input
                      style={estilos.radioButton(param.esComputadora)}
                      type='radio'
                      id='SP'
                      name='opcionesprev'
                      value='S'
                      checked={estadoopcionprev === 'S'}
                      onChange={cambiarestadoopcionPrev}
                      disabled={
                        param.tipoAccion === 'editarinsumo' ? true : false
                      }
                    />
                    <label htmlFor='SP'>Habilitado</label>
                  </div>
                  <div>
                    <input
                      style={estilos.radioButton(param.esComputadora)}
                      type='radio'
                      id='NP'
                      name='opcionesprev'
                      value='N'
                      checked={estadoopcionprev === 'N'}
                      onChange={cambiarestadoopcionPrev}
                      disabled={
                        param.tipoAccion === 'editarinsumo' ? true : false
                      }
                    />
                    <label htmlFor='NP'>Deshabilitado</label>
                  </div>
                </div>
              </div>
              <div style={estilos.campo6_7(param.esComputadora)}>
                Datos del Preventista
              </div>
              <div style={estilos.campo7(param.esComputadora)}>
                <Opciones
                  style={{
                    ...(envioForm &&
                      estilos.estiloVacio(param.esComputadora, empin || previn))
                  }}
                  id='empin'
                  alto='55px'
                  moderno={'Empresa'}
                  opciones={empop !== null ? empop : []}
                  clave='codigo'
                  valor={['nombre']}
                  esComputadora={param.esComputadora}
                  onClick={e => {
                    setempin(e)
                    setprevin('')
                    //setcamempori(true)
                  }}
                  programaOrigen='agregarEditarProductos'
                  valorInicial={empin !== '' ? true : false}
                  deshabilitado={
                    param.tipoAccion === 'editarinsumo' ? true : false
                  }
                />
              </div>
              <div style={estilos.campo8(param.esComputadora)}>
                <Opciones
                  style={
                    {
                      /*...(envioForm &&
                      estilos.estiloVacio(param.esComputadora, marcain))
                      */
                    }
                  }
                  id='marcain'
                  alto='55px'
                  moderno={'Marca'}
                  opciones={marcaop !== null ? marcaop : []}
                  clave='codigo'
                  valor={['nombre']}
                  esComputadora={param.esComputadora}
                  onClick={e => {
                    setmarcain(e)
                  }}
                  programaOrigen='agregarEditarProductos'
                  valorInicial={marcain !== '' ? true : false}
                  deshabilitado={
                    param.tipoAccion === 'editarinsumo' ? true : false
                  }
                />
              </div>
              <div style={estilos.campo9(param.esComputadora)}>
                <Opciones
                  style={{
                    ...(envioForm &&
                      estilos.estiloVacio(param.esComputadora, empin || previn))
                  }}
                  id='previn'
                  alto='55px'
                  moderno={'Preventista'}
                  opciones={prevop !== null ? prevop : []}
                  clave='usuario'
                  valor={['nombre']}
                  esComputadora={param.esComputadora}
                  onClick={e => {
                    setprevin(e)
                    setempin('')
                    setmarcain('')
                  }}
                  programaOrigen='agregarEditarProductos'
                  valorInicial={previn !== '' ? true : false}
                  deshabilitado={
                    param.tipoAccion === 'editarinsumo' ? true : false
                  }
                />
              </div>
              <div style={estilos.campo6(param.esComputadora)}>
                <div
                  style={{
                    width: '100%',
                    backgroundColor: 'white',
                    border: '0.1vw solid #cccccc',
                    borderRadius: param.esComputadora ? '0.3vw' : '1vw',
                    ...(envioForm &&
                      estilos.estiloVacio(param.esComputadora, habilitadoIn)),

                    ...(param.tipoAccion === 'editarpreventistainsumo' ||
                      (param.tipoAccion === 'relprevinsumo' && {
                        backgroundColor: '#cccccc',
                        color: '#555555'
                      }))
                  }}
                >
                  <div>
                    <input
                      style={estilos.radioButton(param.esComputadora)}
                      type='radio'
                      id='S'
                      name='opciones'
                      value='S'
                      checked={estadoopcion === 'S'}
                      onChange={cambiarestadoopcion}
                      disabled={
                        param.tipoAccion === 'editarpreventistainsumo' ||
                        param.tipoAccion === 'relprevinsumo'
                          ? true
                          : false
                      }
                    />
                    <label htmlFor='S'>Habilitado</label>
                  </div>
                  <div>
                    <input
                      style={estilos.radioButton(param.esComputadora)}
                      type='radio'
                      id='N'
                      name='opciones'
                      value='N'
                      checked={estadoopcion === 'N'}
                      onChange={cambiarestadoopcion}
                      disabled={
                        param.tipoAccion === 'editarpreventistainsumo' ||
                        param.tipoAccion === 'relprevinsumo'
                          ? true
                          : false
                      }
                    />
                    <label htmlFor='N'>Deshabilitado</label>
                  </div>
                </div>
              </div>
              <div style={estilos.confirmar(param.esComputadora)}>
                <Boton
                  style={{
                    backgroundColor: '#2D8B0B',
                    borderRadius: param.esComputadora ? '0.5vw' : '1vw',
                    padding: param.esComputadora ? '0.8vw' : '2vw'
                  }}
                  textoStyle={{
                    color: 'white',
                    width: '100%',
                    fontSize: param.esComputadora ? '1vw' : '2.5vw'
                  }}
                  texto='Confirmar'
                  tipo='submit'
                  estaProcesando={false}
                  onClick={async event => {
                    event.preventDefault()
                    console.log(
                      param.tipoAccion === 'editarinsumo' ||
                        param.tipoAccion === 'relprevinsumo'
                        ? codigoElem
                        : null,
                      nombreIn,
                      medidain,
                      unidadmedidain,
                      stockminimoin,
                      habilitadoIn,
                      empin,
                      marcain,
                      previn,
                      habilitadoprevin
                    )
                    setEnvioForm(true)
                    if (
                      nombreIn !== '' &&
                      medidain !== '' &&
                      unidadmedidain !== '' &&
                      stockminimoin !== '' &&
                      habilitadoIn !== '' &&
                      (param.tipoAccion === 'editarinsumo' ||
                        ((empin !== '' || previn !== '') && habilitadoprevin))
                    ) {
                      const registrarInsumo = await api.registroInsumo(
                        [
                          'editarinsumo',
                          'editarpreventistainsumo',
                          'relprevinsumo'
                        ].includes(param.tipoAccion)
                          ? codigoElem
                          : null,
                        nombreIn,
                        parseFloat(medidain),
                        unidadmedidain,
                        parseInt(stockminimoin),
                        habilitadoIn,
                        ['editarpreventistainsumo'].includes(param.tipoAccion)
                          ? parseInt(param.codigoElem2)
                          : 0,
                        empin === '' ? 0 : parseInt(empin),
                        marcain === '' ? 0 : parseInt(marcain),
                        previn === '' ? 0 : previn,
                        habilitadoprevin,
                        stockminimoin,
                        'N',
                        'N'
                      )
                      console.log(registrarInsumo)
                      if (registrarInsumo) {
                        if (registrarInsumo.SMListaErrores.length === 0) {
                          console.log('exito')
                          param.cerrarModal()
                        } else {
                          console.log('fallo')
                        }
                      }
                    }
                  }}
                />
              </div>
              <div style={estilos.cancelar(param.esComputadora)}>
                <Boton
                  style={{
                    backgroundColor: '#E12121',
                    borderRadius: param.esComputadora ? '0.5vw' : '1vw',
                    padding: param.esComputadora ? '0.8vw' : '2vw'
                  }}
                  textoStyle={{
                    color: 'white',
                    width: '100%',
                    fontSize: param.esComputadora ? '1vw' : '2.5vw'
                  }}
                  texto='Cancelar'
                  tipo='submit'
                  estaProcesando={false}
                  onClick={event => {
                    event.preventDefault()
                    param.cerrarModal()
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MAgregarInsumo
