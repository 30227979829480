import React, { useState, useEffect } from 'react'
import ListarPedidos from './Pedidos/ListarPedidos'
import GenerarPedidoDist from './GenerarPedidoDist/GenerarPedidoDist'
import GenerarPedido from './GenerarPedido/GenerarPedido'
import VentaTienda from './VentaTienda/VentaTienda'
import RegistroVentasTienda from './RegistroVentasTienda/RegistroVentasTienda'
import StockProductos from './StockProductos/StockProductos'
import CrearPersona from './InformacionGeneral/Personal/CrearPersona'
import AdministrarProductos from './InformacionGeneral/Productos/AdministrarProductos'
import Asistencias from './Asistencias/Asistencias'
//import Empresas from './InformacionGeneral/General/Empresas'
import Empresas from './InformacionGeneral/General/Empresas/Empresas'
import Marcas from './InformacionGeneral/General/Marcas/Marcas'
import Preventistas from './InformacionGeneral/Personal/Preventistas/Preventistas'
import Insumos from './InformacionGeneral/Insumos/Insumos'
import ListaIngresos from './Almacen/ListaIngresos/ListaIngresos'
import GenerarPedidoInsumos from './Almacen/GenerarPedidoInsumos/GenerarPedidoInsumos'
import ListaSalidas from './Almacen/ListaSalidas/ListaSalidas'
import GenerarSalidaInsumos from './Almacen/GenerarSalidaInsumo/GenerarSalidaInsumos'
import StockAlmacen from './Almacen/StockAlmacen/StockAlmacen'

function ContenidoCentral (param) {
  

  const contenedorContenidoCentralstyle = {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    padding: param.esComputadora ? '2vw':'4vw'
  }
  const contenidostyle = {
    display: 'grid',
    gridTemplateRows: 'repeat(20, 1fr)',
    gridTemplateColumns: 'repeat(20, 1fr)',
    width: '100%',
    height: '100%',
    padding: param.esComputadora ? '1vw':'2vw',
    boxSizing: 'border-box',
    border: '1px solid #BBBBBB',
    borderRadius: '10px'
  }
  return (
    <>
      <div
        id='contenedorContenidoCentral'
        style={contenedorContenidoCentralstyle}
      >
        <div id='contenido' style={contenidostyle}>
          {param.opcion=='despachoDePedidos' && <ListarPedidos esComputadora={param.esComputadora}/>}
          {param.opcion=='generarPedidoDistribuidor' && <GenerarPedidoDist esComputadora={param.esComputadora}/>}
          {param.opcion=='generarPedido' && <GenerarPedido esComputadora={param.esComputadora}/>}
          {param.opcion=='ventaTienda' && <VentaTienda esComputadora={param.esComputadora}/>}
          {param.opcion=='registroDeVentas' && <RegistroVentasTienda esComputadora={param.esComputadora}/>}
          {param.opcion=='stockProductos' && <StockProductos esComputadora={param.esComputadora}/>}
          {param.opcion=='crearPersona' && <CrearPersona esComputadora={param.esComputadora}/>}
          {param.opcion=='administrarProductos' && <AdministrarProductos esComputadora={param.esComputadora}/>}
          {param.opcion=='asistencias' && <Asistencias esComputadora={param.esComputadora}/>}
          {param.opcion=='empresasig' && <Empresas/>}
          {param.opcion=='marcasig' && <Marcas/>}
          {param.opcion=='preventistaig' && <Preventistas/>}
          {param.opcion=='insumosig' && <Insumos/>}
          {param.opcion=='listaingresosalmacen' && <ListaIngresos/>}
          {param.opcion=='generarpedidoinsumo' && <GenerarPedidoInsumos/>}
          {param.opcion=='listasalidasalmacen' && <ListaSalidas/>}
          {param.opcion=='generarsalidainsumos' && <GenerarSalidaInsumos/>}
          {param.opcion=='stockalmacen' && <StockAlmacen/>}
        </div>
      </div>
    </>
  )
}

export default ContenidoCentral
