import { hexToRGBA } from '../../../../Api/Api'

import { StyleSheet } from '@react-pdf/renderer'

export const baseModal = esComputadora => ({
  position: 'absolute',
  left: '0',
  top: '0',
  width: '100vw',
  height: '100vh',
  backgroundColor: hexToRGBA('#C1C1C1', 0.5),
  zIndex: 40
})
export const botonCerrarModalStyle = esComputadora => ({
  display: 'flex',
  gridArea: esComputadora ? ' 1 / 18 / 2 / 21' : ' 1 / 18 / 2 / 21',
  justifySelf: 'right',
  alignItems: 'center'
})

const widthModal = esComputadora => {
  return esComputadora ? '90' : '90'
}
const heightModal = esComputadora => {
  return esComputadora ? '90' : '90'
}
export const contenedorModal = esComputadora => ({
  borderRadius: '10px 10px 0px 0px',
  display: 'grid',
  gridTemplateColumns: 'repeat(4,1fr)',
  gridTemplateRows: 'repeat(2,1fr)',
  position: 'absolute',
  left: (100 - widthModal(esComputadora)) / 2 + 'vw',
  top: (100 - heightModal(esComputadora)) / 2 - 2.5 + 'vh',
  width: widthModal(esComputadora) + 'vw',
  backgroundColor: hexToRGBA('#ffffff', 1),
  zIndex: '41',
  boxSizing: 'border-box'
})
export const tituloCSS = esComputadora => ({
  gridArea: esComputadora ? ' 1 / 2 / 1 / 4' : ' 1 / 2 / 1 / 4',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  //backgroundColor: "green",
  fontSize: esComputadora ? '1.2vw' : '3.5vw'
})
export const contenidoModal = esComputadora => ({
  gridArea: '1/1/21/21',
  backgroundColor: 'red',
  position: 'absolute',
  top: '5vh',
  width: widthModal(esComputadora) + 'vw',
  height: heightModal(esComputadora) + 'vh',
  backgroundColor: hexToRGBA('#ffffff', 1),
  zIndex: '42',
  borderRadius: esComputadora ? '0px 0px 0.5vw 0.5vw' : '0px 0px 1vw 1vw'
})
export const formularioCSS = esComputadora => ({
  width: '100%',
  height: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(20,1fr)',
  gridTemplateRows: 'repeat(20,1fr)',
  //marginTop: esComputadora ? "1vw":"2vw",
  padding: '1vw 0vw 0vw 0vw',
  boxSizing: 'border-box'
})

export const textRangoFechas = esComputadora => ({
  fontSize: esComputadora ? '1vw' : '2.3vw',
  fontFamily: 'arial, verdana',
  //backgroundColor: "green",
  boxSizing: 'border-box',
  padding: '1vw 0',
  borderBottom: 'solid 0.2vw #cccccc'
  //borderTop: "solid 0.2vw #cccccc"
})

export const centrarElementos = esComputadora => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
})

export const prevVis = esComputadora => ({
  //backgroundColor: "green",
  border: 'solid 0.1vw #cccccc',
  boxShadow: 'inset 0.1vw 0.1vw 1vw #cccccc',
  //overflow: 'auto'
})
/*
export const estiloVacio = (esComputadora, envioForm, entrada) => ({
    border: !envioForm || entrada ? '1px solid #ccc' : '1px solid red',
    boxShadow:
      !envioForm || entrada ? 'inset 0 0 1px gray' : 'inset 0 0 5px red',
    
})*/

export const estiloVacio = (esComputadora, vacio) => ({
  //borderColor: usuarioVacio ? 'red':fg.hex_rgb("#ffffff",0),
  border:
    vacio || vacio === '' || vacio === 0
      ? `${esComputadora ? '0.1vw' : '0.2vw'} solid red`
      : `${esComputadora ? '0.1vw' : '0.2vw'} solid #ccc`
})

//DOCUMENTO PDF
export const docpdf = StyleSheet.create({
    pagina: { 
        padding: 5, 
        backgroundColor: 'white' 
    },
    tabla: {
        display: 'table',
        width: '100%',
        height: '100%',
        border: 1,
        padding: 10,
        margin: 0,
    },
    dfila: {
        flexDirection: "row",
    },
    dcolumna: {
        flexDirection: "column",
    },
    celda: {
        //backgroundColor: "green",
        width: "25%",
        height: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: 1,
        margin: 0,
        fontSize: "10px",
        textAlign: "center"
    },
    col1:{
        width: "14%",
    },
    col2:{
        width: "8%",
    },
    col3:{
        width: "70%",
    },
    col4:{
        width: "40%",
    },
    col5:{
        width: "8%",
    },
    col3f1:{
        width: "100%",
        height: "33%",
        border: 0,
        borderBottom: 1,
    },
    col3f2:{
        width: "100%",
        height: "66%",
        border: 0,
    },
    col3f2c1:{
        width: "80%",
        height: "100%",
        border: 0,
        borderRight: 1,
    },
    col3f2c2:{
        width: "10%",
        height: "100%",
        border: 0,
        borderRight: 1,
    },
    col3f2c3:{
        width: "10%",
        height: "100%",
        border: 0,
        
    },
    col3f2c1f1:{
        width: "100%",
        border: 0,
        borderBottom: 1
    },
    col3f2c1f2:{
        width: "100%",
        border: 0,
    },
    col3f2c1f2cn:{
        width: "25%",
        height: "100%",
        border: 0,
        borderRight: 1,
        fontSize: "8px"
    },
    scol2:{
        width: "78%",
    },
    scol2f2c1:{
        width: "50%",
        height: "100%",
        border: 0,
    },
    scol2f2c1f1:{
        width: "100%",
        height: "100%",
        border: 0,
        borderBottom: 1,
        borderRight: 1,
    },
    scol3f2c1f2:{
        width: "100%",
        border: 0,
    },
    categorias: {
      height: "12px",
      border: 1,
      borderBottom: 1,
      borderLeft: 1,
      borderRight: 1,
      width: "100%",
      fontSize: "8",
      backgroundColor: "#cccccc"
    },
    fregistroProd: {
      border: 0,
      borderBottom: 1,
      borderLeft: 1,
      borderRight: 1,
      width: "100%",
      fontSize: "7",
      
    },
    fProd: {
      width: "25%",
      fontSize: "5px",
      border: 1,
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    nprod:{
      width: "14%",
      border: 0,
      borderRight: 1,
    },
    sini:{
      width: "8%",
      border: 0,
      borderRight: 1,
      textAlign: "center"
    },
    ingUsr: {
      width: "71%",
      border: 0,
      borderRight: 1
    },
    stkfinal:{
      width: '8%',
      textAlign: "center"
    },
    usuarios:{
      width: "25%",
      borderRight: 1,
      textAlign: "center",
    },
    total:{
      width: "10%",
      textAlign: "center",
    },
    pendiente:{
      width: "9%",
      textAlign: "center",
      borderRight: 1
    },
    usufec: {
      width: "12.5%",
      borderRight: 1
    },
    salUsr: {
      width: "78%",
      border: 0,
      borderRight: 1,
    },
    stotal:{
      width: "10%",
      textAlign: "center",
    },
    susufec: {
      width: "50%",
      borderRight: 1
    },
    stienda:{
      width: "7%",
      textAlign: "center",
      borderRight: 1,
    },
    sctotal:{
      width: "6.5%",
      textAlign: "center",
      borderRight: 1
    },
})
