import React, { useState, useEffect, useContext } from 'react'
import { Contexto } from '../../../index'
import * as estilos from './GenerarSalidaInsumosCSS'
import Input from '../../../../../Elementos/Input'
import Opciones from '../../../../../Elementos/Opciones'
import Boton from '../../../../../Elementos/Boton'
import * as api from '../../../../../Api/Api'
import * as fg from '../../../../../Api/FuncionesGlobales'

function GenerarSalidaInsumos (param) {
  const { esComputadora, rol } = useContext(Contexto)
  const [empop, setempop] = useState(null)
  const [prevop, setprevop] = useState(null)
  const [insop, setinsop] = useState(null)
  const [inslist, setinslist] = useState(null)
  const [maesop, setmaesop] = useState(null)

  const [previn, setprevin] = useState('')
  const [empin, setempin] = useState('')
  const [pedidoinfo, setpedidoinfo] = useState(null)

  const [listPedIns, setListPedIns] = useState([])
  const [comenpedin, setcomenpedin] = useState('')

  const [agregarin, setagregarin] = useState(false)
  const [insin, setinsin] = useState('')
  const [prevlist, setprevlist] = useState([])

  const [motlist, setmotlist] = useState(
    rol === 'SUPER ADMINISTRADOR'
      ? [
          { cod: 1, dsc: 'PRODUCCIÓN' },
          { cod: 2, dsc: 'TRASLADO' },
          { cod: 3, dsc: 'REAJUSTE' }
        ]
      : [
          { cod: 1, dsc: 'PRODUCCIÓN' },
          { cod: 2, dsc: 'TRASLADO' }
        ]
  )
  const [motin, setmotin] = useState('')
  const [maesin, setmaesin] = useState('')
  const [salin, setsalin] = useState('')
  const [actualizo, setactualizo] = useState(false)
  useEffect(() => {
    const inicio = async () => {
      const maestrosCall = await api.listarMaestros(null, 'S')
      if (maestrosCall) {
        if (maestrosCall.SMListaErrores.length === 0) {
          setmaesop(maestrosCall.maestro)
        }
      }
      const insCall = await api.listarInsumos(
        null,
        'S',
        null,
        null,
        null,
        null,
        'S'
      )
      const empcall = await api.listarEmpresas(null, 'S', 'N')
      const prevcall = await api.listarPreventistas(0, 'S')
      if (empcall && prevcall && insCall) {
        if (
          empcall.SMListaErrores.length === 0 &&
          prevcall.SMListaErrores.length === 0 &&
          insCall.SMListaErrores.length === 0
        ) {
          setempop(empcall.empresa)
          setprevop(prevcall.preventistas)
          let insumosTemp = insCall.insumo.map(ip => ip.insumo)
          setinsop(insumosTemp)
          setinslist(insCall.insumo)
        }
      }
    }
    inicio()
  }, [])
  const salgodeinput = async e => {
    console.log('entre al original')
    let etiqueta = e.currentTarget.parentNode.parentNode.parentNode
    let valor =
      e.currentTarget.value === '' ? 0 : parseFloat(e.currentTarget.value)
    
    console.log(etiqueta.getAttribute('insumo'))
    console.log(etiqueta.getAttribute('empresa'))
    console.log(etiqueta.getAttribute('marca'))
    console.log(etiqueta.getAttribute('preventista'))
    console.log(valor)
    console.log(salin)
    const editsalins = await api.editarSalidaInsumoDetalle(
      salin,
      valor,
      parseInt(etiqueta.getAttribute('insumo')),
      parseInt(etiqueta.getAttribute('empresa')),
      parseInt(etiqueta.getAttribute('marca')),
      etiqueta.getAttribute('preventista'),
    )
    console.log(editsalins)
    setactualizo(prev => !prev)

  }
  useEffect(() => {
    console.log(empin, previn)
    const actualizar = async () => {
      if (empin !== '') {
        const prevdom = document.getElementById('previn')
        if (prevdom) prevdom.value = ''
        setprevin('')

        const insCall = await api.listarInsumos(
          null,
          'S',
          null,
          empin,
          null,
          null,
          'S'
        )
        if (insCall) {
          if (insCall.SMListaErrores.length === 0) {
            let insumosTemp = insCall.insumo.map(ip => ip.insumo)
            setinsop(insumosTemp)
            setinslist(insCall.insumo)
          }
        }
        /*const listapedidosact = await api.pedidoInsumoDetalle(
          null,
          parseInt(empin),
          '0'
        )
        if (listapedidosact) {
          if (listapedidosact.SMListaErrores.length === 0) {
            setpedidoinfo(listapedidosact.pedido)
            setListPedIns(listapedidosact.insumosPedidoJson)
          }
        }
          */
      }
      if (previn !== '') {
        const empdom = document.getElementById('empin')
        if (empdom) {
          empdom.value = ''
        }
        //setempin('')

        const insCall = await api.listarInsumos(
          null,
          'S',
          null,
          null,
          null,
          previn,
          'S'
        )
        if (insCall) {
          if (insCall.SMListaErrores.length === 0) {
            let insumosTemp = insCall.insumo.map(ip => ip.insumo)
            setinsop(insumosTemp)
            setinslist(insCall.insumo)
          }
        }

        /*const listapedidosact = await api.pedidoInsumoDetalle(null, 0, previn)

        if (listapedidosact) {
          if (listapedidosact.SMListaErrores.length === 0) {
            setpedidoinfo(listapedidosact.pedido)
            setListPedIns(listapedidosact.insumosPedidoJson)
          }
        }
          */
      }
      if (empin === '' && previn === '') {
        const prevdom = document.getElementById('previn')
        if (prevdom) prevdom.value = ''
        const empdom = document.getElementById('empin')
        if (empdom) empdom.value = ''
        //setListPedIns([])

        const insCall = await api.listarInsumos(
          null,
          'S',
          null,
          null,
          null,
          null,
          'S'
        )
        if (insCall) {
          if (insCall.SMListaErrores.length === 0) {
            let insumosTemp = insCall.insumo.map(ip => ip.insumo)
            setinsop(insumosTemp)
            setinslist(insCall.insumo)
          }
        }
      }
    }
    actualizar()
  }, [empin, previn])

  useEffect(() => {
    //console.log(inslist)
    if (inslist !== null) {
      let prevlisttemp = inslist.filter(
        ip => parseInt(ip.insumo.codigo) === parseInt(insin)
      )
      //console.log(prevlisttemp)
      if (prevlisttemp.length > 0) setprevlist(prevlisttemp[0].preventistas)
    }
  }, [insin])

  useEffect(() => {
    setempin('')
    setprevin('')
    setinsin('')
    setmaesin('')
    const inicio = async () => {
      if (motin !== '1' && motin !== '') {
        const salinsdetCall = await api.salidaInsumoDetalle(
          null,
          parseInt(motin),
          null
        )
        console.log(salinsdetCall)
        if (salinsdetCall) {
          setsalin(salinsdetCall.salida.salida)
          setListPedIns(salinsdetCall.insumosSalidaJson)
        }
      } else {
        setListPedIns([])
      }
    }
    inicio()
  }, [motin])

  useEffect(() => {
    const inicio = async () => {
      if (motin === '1' && maesin !== '') {
        const salinsdetCall = await api.salidaInsumoDetalle(
          null,
          parseInt(motin),
          maesin
        )
        console.log(salinsdetCall)
        if (salinsdetCall) {
          setsalin(salinsdetCall.salida.salida)
          setListPedIns(salinsdetCall.insumosSalidaJson)
        }
      }
    }
    inicio()
  }, [maesin])

  useEffect(() => {
    if (agregarin) {
      const inicio = async () => {
        const salinsdetCall = await api.salidaInsumoDetalle(
          null,
          parseInt(motin),
          maesin === '' ? null : maesin
        )
        console.log(salinsdetCall)
        setListPedIns(salinsdetCall.insumosSalidaJson)
      }
      inicio()
    }
  }, [actualizo])
  return (
    <>
      <div style={estilos.cabeceraVista(esComputadora)}>
        {'Generar Salida (Insumos)'}
      </div>
      <div style={estilos.cabeceraFiltros(esComputadora)}>
        <div style={estilos.campof1(esComputadora)}>
          <Opciones
            style={
              {
                //width: "80%",
                //height: "10%"
                /*...(envioForm &&
              estilos.estiloVacio(param.esComputadora, ''))*/
              }
            }
            id='motivoin'
            moderno={'Motivo'}
            opciones={motlist !== null ? motlist : []}
            clave='cod'
            valor={['dsc']}
            esComputadora={esComputadora}
            onClick={e => {
              //setmaesin('')
              setmotin(e)
            }}
            programaOrigen='agregarEditarProductos'
            valorInicial={'' !== '' ? true : false}
          />
        </div>
        {motin === '1' && (
          <div style={estilos.campof2(esComputadora)}>
            <Opciones
              style={
                {
                  /*...(envioForm &&
              estilos.estiloVacio(param.esComputadora, ''))*/
                }
              }
              id='maestrosin'
              moderno={'Maestros'}
              opciones={maesop !== null ? maesop : []}
              clave='usuario'
              valor={['nombre', 'apellido_paterno', 'apellido_materno']}
              esComputadora={esComputadora}
              onClick={e => {
                console.log(e)
                setmaesin(e)
              }}
              programaOrigen='agregarEditarProductos'
              valorInicial={'' !== '' ? true : false}
            />
          </div>
        )}
      </div>
      <div style={estilos.cuerpo(esComputadora)}>
        {((motin === '1' && maesin !== '') ||
          (motin !== '1' && motin !== '')) && (
          <div
            style={{
              display: 'grid',
              gridTemplateRows: 'repeat(1,1fr)',
              gridTemplateColumns: 'repeat(3,1fr)',
              //height: "100px"
            }}
          >
            <div
              style={{
                gridArea: '1/1/2/2',
                padding: '1vw',
                display: 'flex',
                alignItems: 'center',
                boxSizing: "border-box"
              }}
            >
              <Opciones
                style={
                  {
                    /*...(envioForm &&
              estilos.estiloVacio(esComputadora, ''))*/
                  }
                }
                id='empin'
                moderno={'Empresas'}
                opciones={empop !== null ? empop : []}
                clave='codigo'
                valor={['nombre']}
                esComputadora={esComputadora}
                onClick={e => {
                  setempin(e)
                  setprevin('')
                }}
                programaOrigen='agregarEditarProductos'
                valorInicial={empin !== '' ? true : false}
              />
            </div>
            <div
              style={{
                gridArea: '1/2/2/3',
                padding: '1vw',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Opciones
                style={
                  {
                    /*...(envioForm &&
              estilos.estiloVacio(esComputadora, ''))*/
                  }
                }
                id='previn'
                moderno={'Preventistas'}
                opciones={prevop !== null ? prevop : []}
                clave='usuario'
                valor={['nombre', 'apellido_paterno', 'apellido_materno']}
                esComputadora={esComputadora}
                onClick={e => {
                  setprevin(e)
                  setempin('')
                }}
                programaOrigen='agregarEditarProductos'
                valorInicial={previn !== '' ? true : false}
              />
            </div>
            <div
              style={{
                gridArea: '1/3/2/4',
                padding: '1vw',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Opciones
                style={
                  {
                    /*...(envioForm &&
                estilos.estiloVacio(param.esComputadora, ''))*/
                  }
                }
                id='isumosin'
                moderno={'Insumos'}
                opciones={insop !== null ? insop : []}
                clave='codigo'
                valor={['nombre']}
                esComputadora={esComputadora}
                onClick={e => {
                  setinsin(e)
                }}
                programaOrigen='agregarEditarProductos'
                valorInicial={'' !== '' ? true : false}
              />
            </div>
            <div
              style={{
                gridArea: esComputadora ? '1/4/2/5':'2/1/3/4',
                padding: '1vw',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Boton
                style={{
                  backgroundColor: '#2D8B0B',
                  borderRadius: esComputadora ? '0.5vw' : '1vw',
                  padding: esComputadora ? '0.8vw' : '2vw',
                  width: esComputadora ? '100%' : '100%'
                }}
                textoStyle={{
                  color: 'white',
                  width: '100%',
                  fontSize: esComputadora ? '1vw' : '2.5vw'
                }}
                texto={'Agregar Insumo'}
                tipo='submit'
                tonalidad='20'
                estaProcesando={false}
                onClick={async () => {
                  console.log(salin)
                  console.log(prevlist)

                  const llamado = async () => {
                    for (const element of prevlist) {
                      console.log(element.codigo)
                      const edsalinsdetCall =
                        await api.editarSalidaInsumoDetalle(
                          parseInt(salin),
                          -1,
                          parseInt(element.insumo),
                          parseInt(element.empresa.codigo),
                          parseInt(element.marca.codigo),
                          element.preventistas.usuario
                        )
                      if(edsalinsdetCall){
                        if(edsalinsdetCall.SMListaErrores.length === 0){
                          console.log("exito")
                        }else{
                          console.log(edsalinsdetCall)
                        }
                      }
                      
                    }
                  }

                  await llamado()
                  console.log('esperando que termine el llamado')
                  setagregarin(true)
                  setactualizo(prev => !prev)
                }}
              />
            </div>
          </div>
        )}
        <div id='contitem' style={estilos.contenidoItems(esComputadora)}>
          {listPedIns.length > 0 &&
            listPedIns.map(lp => (
              <div
                id={lp.insumo.codigo}
                key={lp.insumo.codigo}
                style={estilos.cuerpoitem(esComputadora)}
              >
                <div style={estilos.cuerpoitemcab(esComputadora)}>
                  {lp.insumo.nombre +
                    ' (' +
                    lp.insumo.unidades +
                    ' ' +
                    lp.insumo.unidad_medida +
                    ')'}
                </div>
                <div style={estilos.cuerpoitemcue(esComputadora)}>
                  <div
                    style={{
                      ...estilos.cuerpoitemcuecab(esComputadora),
                      fontWeight: 'bolder'
                      //height: '20%'
                    }}
                  >
                    <div style={estilos.campo0(esComputadora)}>
                      Empresa(Marca)
                    </div>
                    <div style={estilos.campo1(esComputadora)}>Preventista</div>
                    <div style={estilos.campo2(esComputadora)}>
                      Cant. Solicitada
                    </div>
                  </div>
                  {lp.detalleSalida.length > 0 &&
                    lp.detalleSalida.map(dp => (
                      <div
                        id={dp.empresa.codigo}
                        key={dp.empresa.codigo}
                        empresa={dp.empresa.codigo}
                        marca={dp.marca.codigo}
                        preventista={dp.preventista.usuario}
                        insumo={dp.insumo}
                        style={{
                          ...estilos.cuerpoitemcuecue(esComputadora)
                          //height: '80%'
                        }}
                      >
                        <div style={estilos.campo0(esComputadora)}>
                          {dp.empresa.nombre + '-' + dp.marca.nombre}
                        </div>
                        <div style={estilos.campo1(esComputadora)}>
                          {fg.capitalizar_palabra(
                            dp.preventista.nombre +
                              ' ' +
                              dp.preventista.apellido_paterno
                          )}
                        </div>
                        <div style={estilos.campo2(esComputadora)}>
                          <Input
                            style={{
                              fontWeight: 'bolder',
                              fontSize: esComputadora ? '1.2vw' : '2.5vw',
                              padding: esComputadora ? '0.2vw' : '0.7vw',
                              width: '100%'
                            }}
                            textoStyle={{
                              fontWeight: 'bolder',
                              fontSize: esComputadora ? '1vw' : '2.5vw'
                            }}
                            id='inputin'
                            moderno=''
                            tipo='number'
                            maxDigitos='10'
                            autoComplete
                            onChange={e => {
                              if (e.currentTarget.value === '') {
                                salgodeinput(e)
                                //console.log("entre")
                              }
                            }}
                            onBlur={salgodeinput}
                            valorInicial={dp.cantidad}
                            esComputadora={esComputadora}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))}
        </div>
      </div>
      <div style={estilos.opcionesBotones(esComputadora)}>
        <div>
          <Boton
            style={{
              backgroundColor: '#2D8B0B',
              borderRadius: esComputadora ? '0.5vw' : '1vw',
              padding: esComputadora ? '0.8vw' : '2vw'
            }}
            textoStyle={{
              color: 'white',
              width: '100%',
              fontSize: esComputadora ? '1vw' : '2.5vw'
            }}
            texto={
              rol === 'SUPER ADMINISTRADOR'
                ? 'Aprobar Pedido'
                : 'Finalizar Salida'
            }
            tipo='submit'
            tonalidad='20'
            estaProcesando={false}
            onClick={async () => {
              console.log(salin)
              const guardsalinsu = await api.guardarSalidaInsumo(
                salin,1
              )
              if(guardsalinsu){
                if(guardsalinsu.SMListaErrores.length===0){
                  console.log("éxitos")
                  setListPedIns([])
                  
                  
                  let motivodom = document.getElementById('motivoin')
                  if (motivodom) motivodom.value = ''
                  setmotin('')
                  console.log(motivodom)
                }else{
                  console.log(guardsalinsu)
                }
              }
            }}
          />
        </div>
      </div>
    </>
  )
}

export default GenerarSalidaInsumos
