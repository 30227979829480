import React, { useState, useEffect, useRef, useCallback } from 'react'
import * as estilos from './MAgregarEmpresaCSS'
import Boton from '../../../../../../Elementos/Boton'
import Input from '../../../../../../Elementos/Input'
import Opciones from '../../../../../../Elementos/Opciones'
import * as api from '../../../../../../Api/Api'
import * as fg from '../../../../../../Api/FuncionesGlobales'

function MAgregarEmpresa (param) {
  const [estaProcesando, setestaProcesando] = useState(false)
  const [estadoOpcion, setEstadoOpcion] = useState(
    (function () {
      if (param.tipo === 'editar') {
        if (Object.keys(param.param['productos']).length !== 1) {
          return 'NULL'
        }
      }
    })()
  )
  const [propietarioOp, setPropietarioOp] = useState([
    { codigo: "S", dsc: 'Si' },
    { codigo: "N", dsc: 'No' }
  ])
  const [nombreIn, setNombreIn] = useState('')
  const [rucIn, setRucIn] = useState('')
  const [propioIn, setPropioIn] = useState('')
  const [habilitadoIn, setHabilitadoIn] = useState('')
  const [envioForm, setEnvioForm] = useState(false)
  const [codigoElem, setCodigoElem] = useState(null)

  useEffect(() => {
    console.log(param)
    if(param.codigoElem !== null){
      const obtenerEmpresa = async ()=>{
        const respuesta = await api.listarEmpresas(param.codigoElem,'','')
        if(respuesta){
          console.log(respuesta)
          setNombreIn(respuesta.empresa.nombre)
          setRucIn(respuesta.empresa.ruc)
          setPropioIn(respuesta.empresa.propietaria)
          setHabilitadoIn(respuesta.empresa.activo)
          setEstadoOpcion(respuesta.empresa.activo)
          setCodigoElem(param.codigoElem)

          const elementoSeleccionado = document.getElementById('propioEmp')
          if(elementoSeleccionado)
            elementoSeleccionado.value = respuesta.empresa.propietaria
        }
      }
      obtenerEmpresa()
    }
    
  }, [])

  const cambiarEstadoOpcion = event => {
    //console.log(event.target.value)
    setEstadoOpcion(event.target.value)
    setHabilitadoIn(event.target.value)
    //param.cambiarEstadoOpcion(event.target.value)
  }
  return (
    <>
      <div id='baseModal' style={estilos.baseModal(param.esComputadora)}>
        <div
          id='contenedorModal'
          style={estilos.contenedorModal(param.esComputadora)}
        >
          <div style={estilos.botonCerrarModalStyle(param.esComputadora)}>
            <Boton
              style={{
                backgroundColor: '#E12121',
                borderRadius: param.esComputadora ? '0.5vw' : '1vw',
                padding: param.esComputadora ? '0.8vw' : '2vw'
              }}
              textoStyle={{
                color: 'white',
                width: '100%',
                fontSize: param.esComputadora ? '1vw' : '2.5vw'
              }}
              texto='X'
              tipo='submit'
              tonalidad='20'
              estaProcesando={estaProcesando}
              onClick={() => {
                param.cerrarModal()
              }}
            />
          </div>
          <div style={estilos.tituloCSS(param.esComputadora)}>
            {param.tipoAccion === 'agregarEmpresa'
              ? 'Agregar Empresa'
              : 'Editar Empresa'}
          </div>
          <div
            id='contenidoModal'
            style={estilos.contenidoModal(param.esComputadora)}
          >
            <div style={estilos.formularioCSS(param.esComputadora)}>
              <div style={estilos.nombre(param.esComputadora)}>
                <Input
                  style={{
                    ...(envioForm &&
                      estilos.estiloVacio(param.esComputadora, nombreIn))
                  }}
                  textoStyle={{}}
                  id='nombres'
                  moderno={'Nombre'}
                  tipo='text'
                  onChange={e => {
                    setNombreIn(e.currentTarget.value)
                  }}
                  valorInicial={nombreIn !== '' ? nombreIn : ''}
                  esComputadora={param.esComputadora}
                />
              </div>
              <div style={estilos.ruc(param.esComputadora)}>
                <Input
                  style={{
                    ...(envioForm &&
                      estilos.estiloVacio(param.esComputadora, rucIn))
                  }}
                  textoStyle={{}}
                  id='nombres'
                  moderno={'RUC'}
                  tipo='number'
                  maxDigitos = "11"
                  onChange={e => {
                    setRucIn(e.currentTarget.value)
                  }}
                  valorInicial={rucIn !== '' ? rucIn : ''}
                  esComputadora={param.esComputadora}
                />
              </div>
              {console.log(propioIn !=='' ? propioIn:'')}
              <div style={estilos.propietario(param.esComputadora)}>
                <Opciones
                  style = {{...(envioForm && estilos.estiloVacio(param.esComputadora,propioIn))}}
                  id='propioEmp'
                  alto='55px'
                  moderno={'Propio'}
                  opciones={propietarioOp !== null ? propietarioOp : []}
                  clave='codigo'
                  valor={['dsc']}
                  esComputadora={param.esComputadora}
                  onClick={e => {
                    setPropioIn(e)
                  }}
                  programaOrigen='agregarEditarProductos'
                  valorInicial = {param.codigo !==null ? true:false}
                />
              </div>
              <div style={estilos.activo(param.esComputadora)}>
                <div
                  style={{
                    width: '100%',
                    backgroundColor: 'white',
                    border: '0.1vw solid #cccccc',
                    borderRadius: param.esComputadora ? '0.3vw' : '1vw',
                    ...(envioForm && estilos.estiloVacio(param.esComputadora,habilitadoIn))
                  }}
                >
                  <div>
                    <input
                      type='radio'
                      id='S'
                      name='opciones'
                      value='S'
                      checked={estadoOpcion === 'S'}
                      onChange={cambiarEstadoOpcion}
                    />
                    <label htmlFor='S'>Habilitado</label>
                  </div>
                  <div>
                    <input
                      type='radio'
                      id='N'
                      name='opciones'
                      value='N'
                      checked={estadoOpcion === 'N'}
                      onChange={cambiarEstadoOpcion}
                    />
                    <label htmlFor='N'>Deshabilitado</label>
                  </div>
                </div>
              </div>
              <div style={estilos.confirmar(param.esComputadora)}>
                <Boton
                  style={{
                    backgroundColor: '#2D8B0B',
                    borderRadius: param.esComputadora ? '0.5vw' : '1vw',
                    padding: param.esComputadora ? '0.8vw' : '2vw'
                  }}
                  textoStyle={{
                    color: 'white',
                    width: '100%',
                    fontSize: param.esComputadora ? '1vw' : '2.5vw'
                  }}
                  texto='Confirmar'
                  tipo='submit'
                  estaProcesando={false}
                  onClick={async event => {
                    event.preventDefault()
                    setEnvioForm(true)
                    if (
                      nombreIn !== '' &&
                      rucIn !== '' &&
                      propioIn !== '' &&
                      habilitadoIn !== ''
                    ) {
                      console.log(codigoElem)
                      const agregarEmpresaCall = await api.registroEmpresa(
                        codigoElem,
                        nombreIn,
                        parseInt(rucIn,10),
                        habilitadoIn,
                        propioIn,
                        'N'
                      )
                      if (agregarEmpresaCall) {
                        console.log(propioIn)
                        console.log(agregarEmpresaCall)
                        param.cerrarModal()
                        
                      }
                    }
                  }}
                />
              </div>
              <div style={estilos.cancelar(param.esComputadora)}>
                <Boton
                  style={{
                    backgroundColor: '#E12121',
                    borderRadius: param.esComputadora ? '0.5vw' : '1vw',
                    padding: param.esComputadora ? '0.8vw' : '2vw'
                  }}
                  textoStyle={{
                    color: 'white',
                    width: '100%',
                    fontSize: param.esComputadora ? '1vw' : '2.5vw'
                  }}
                  texto='Cancelar'
                  tipo='submit'
                  estaProcesando={false}
                  onClick={event => {
                    event.preventDefault()
                    param.cerrarModal()
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MAgregarEmpresa
