import { useEffect, useState, useContext } from 'react'
import { Contexto } from '../../../../index'
import * as e from './EmpresasCSS'
import * as api from '../../../../../../Api/Api'
import * as fg from '../../../../../../Api/FuncionesGlobales'
import agregar from './../../../../../../Imagenes/Iconos/agregar.png'
import editar from './../../../../../../Imagenes/Iconos/editar.png'
import borrar from './../../../../../../Imagenes/Iconos/borrar.png'
import Boton from '../../../../../../Elementos/Boton'
import MAgregarEmpresa from './MAgregarEmpresa'
import MEliminarEmpresa from './MEliminarEmpresa'

function Empresas () {
  const { esComputadora } = useContext(Contexto)

  const [empresas, setEmpresas] = useState([])
  //eventos
  const [elemSelec, setElemSelec] = useState(null)
  const [dscElemSelec, setDscElemSelec] = useState(null)
  //modales
  const [verModal, setVerModal] = useState(false)
  const [tipoAccion, setTipoAccion] = useState('')

  useEffect(() => {
    if (!verModal) {
      const inicio = async () => {
        const listaEmpresasCall = await api.listarEmpresas(null, null, null)
        if (listaEmpresasCall) {
          setEmpresas(listaEmpresasCall.empresa)
        }
      }
      inicio()
    }
  }, [verModal])

  const cerrarModal = e => {
    setVerModal(false)
  }
  return (
    <>
      {verModal && (
        <div>
          {tipoAccion === 'agregarEmpresa' || tipoAccion === 'editarEmpresa' ? (
            <MAgregarEmpresa
              tipoAccion={tipoAccion}
              cerrarModal={cerrarModal}
              esComputadora={esComputadora}
              codigoElem={elemSelec}
            />
          ) : (
            <MEliminarEmpresa
              dscElemSelec={dscElemSelec}
              tipoAccion={tipoAccion}
              cerrarModal={cerrarModal}
              esComputadora={esComputadora}
              codigoElem={elemSelec}
            />
          )}
        </div>
      )}
      <div id='contenedor' style={e.contenedor(esComputadora)}>
        <div id='titulo' style={e.titulo(esComputadora)}>
          Empresas
        </div>
        <div id='centro' style={e.centro(esComputadora)}>
          <div id='listado' style={e.listado(esComputadora)}>
            <div id='cabeceraListado' style={e.cabeceraListado(esComputadora)}>
              <div
                style={{
                  borderLeft: '0.1vw solid black',
                  ...e.elemCabListado(esComputadora)
                }}
              >
                Nombre
              </div>
              <div style={e.elemCabListado(esComputadora)}>RUC</div>
              <div style={e.elemCabListado(esComputadora)}>Propio</div>
              <div style={e.elemCabListado(esComputadora)}>Activo</div>
              <div style={e.elemCabListado(esComputadora)}>Opciones</div>
            </div>
            {empresas.length > 0 &&
              empresas.map(emp => (
                <div
                  id={emp.codigo}
                  key={emp.codigo}
                  onMouseEnter={() => {
                    setElemSelec(emp.codigo)
                  }}
                  onMouseLeave={() => {
                    setElemSelec(null)
                  }}
                  
                  style={e.cuerpoListado(esComputadora)}
                >
                  <div
                    style={{
                      ...e.elemCuerpoListado(esComputadora),
                      ...(emp.activo === 'N' && e.inactivoElem(esComputadora)),
                      ...(elemSelec === emp.codigo &&
                        e.elemCuerpoHoverListado(esComputadora))
                    }}
                  >
                    {emp.nombre}
                  </div>
                  <div
                    style={{
                      ...e.elemCuerpoListado(esComputadora),
                      ...(emp.activo === 'N' && e.inactivoElem(esComputadora)),
                      ...(elemSelec === emp.codigo &&
                        e.elemCuerpoHoverListado(esComputadora))
                    }}
                  >
                    {emp.ruc}
                  </div>
                  <div
                    style={{
                      ...e.elemCuerpoListado(esComputadora),
                      ...(emp.activo === 'N' && e.inactivoElem(esComputadora)),
                      ...(elemSelec === emp.codigo &&
                        e.elemCuerpoHoverListado(esComputadora))
                    }}
                  >
                    {emp.propietaria === 'S' ? 'Si' : 'No'}
                  </div>
                  <div
                    style={{
                      ...e.elemCuerpoListado(esComputadora),
                      ...(emp.activo === 'N' && e.inactivoElem(esComputadora)),
                      ...(elemSelec === emp.codigo &&
                        e.elemCuerpoHoverListado(esComputadora))
                    }}
                  >
                    {emp.activo === 'S' ? 'Si' : 'No'}
                  </div>
                  <div
                    style={{
                      ...e.elemCuerpoOpListado(esComputadora),
                      ...(emp.activo === 'N' && e.inactivoElem(esComputadora)),
                      ...(elemSelec === emp.codigo &&
                        e.elemCuerpoHoverListado(esComputadora))
                    }}
                  >
                    <img
                    
                      src={editar}
                      style={e.img(esComputadora)}
                      onClick={e => {
                        setVerModal(true)
                        setTipoAccion('editarEmpresa')
                        setElemSelec(e.currentTarget.parentNode.parentNode.id)
                      }}
                    />
                    <img
                      src={borrar}
                      style={e.img(esComputadora)}
                      onClick={e => {
                        setDscElemSelec(e.currentTarget.parentNode.parentNode.firstElementChild.innerHTML)
                        setVerModal(true)
                        setTipoAccion('eliminarEmpresa')
                        setElemSelec(e.currentTarget.parentNode.parentNode.id)
                      }}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div style={e.opciones(esComputadora)}>
          <div>
            <Boton
              style={{
                backgroundColor: '#2D8B0B',
                borderRadius: esComputadora ? '0.5vw' : '1vw',
                padding: esComputadora ? '0.8vw' : '2vw'
              }}
              textoStyle={{
                color: 'white',
                width: '100%',
                fontSize: esComputadora ? '1vw' : '2.5vw'
              }}
              texto='Agregar Empresa'
              tipo='submit'
              estaProcesando={false}
              id='agregarEmpresa'
              onClick={e => {
                setVerModal(true)
                setTipoAccion(e.currentTarget.id)
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Empresas
